import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addDevice, updateDevice, deleteDevice } from "./deviceAPI";

const initialState = {
  device: null,
  status: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const addDeviceAsync = createAsyncThunk(
  "device/addDeviceAsync",
  async (device, { rejectWithValue }) => {
    try {
      const response = await addDevice(device);
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.message;

      if (err.response.status === 401) {
        errResponse.message = "the device could not be added!";
      }

      if (
        err.response.status === 400 &&
        err.response.data.includes("Duplicate entry") &&
        err.response.data.includes("uniqueid")
      ) {
        errResponse.message = "Identifier already in use!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const updateDeviceAsync = createAsyncThunk(
  "device/updateDeviceAsync",
  async (device, { rejectWithValue }) => {
    try {
      const response = await updateDevice(device);
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.message;

      if (err.response.status === 401) {
        errResponse.message = "the device could not be updated!";
      }

      if (
        err.response.status === 400 &&
        err.response.data.includes("Duplicate entry") &&
        err.response.data.includes("uniqueid")
      ) {
        errResponse.message = "Identifier already in use!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const deleteDeviceAsync = createAsyncThunk(
  "device/deleteDeviceAsync",
  async (device, { rejectWithValue }) => {
    try {
      const response = await deleteDevice(device);
      //The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.message;

      if (err.response.status === 401) {
        errResponse.message = "the device could not be deleted!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const deviceSlice = createSlice({
  name: "device",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(addDeviceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addDeviceAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.device = action.payload;
      })
      .addCase(addDeviceAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(updateDeviceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateDeviceAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.device = action.payload;
      })
      .addCase(updateDeviceAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(deleteDeviceAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteDeviceAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.device = null;
      })
      .addCase(deleteDeviceAsync.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

// export const {} = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectDevice = (state) => state.device;

export const selectDeviceQueryState = (state) => state.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default deviceSlice.reducer;
