export function userFormToJson(formValues, attributes, user) {
  const tempUser = {};

  tempUser.id = user ? user.id : 0;
  tempUser.name = formValues.name;
  tempUser.email = formValues.email;
  tempUser.password = formValues?.password;
  tempUser.phone = formValues?.phone;
  tempUser.latitude = formValues.latitude;
  tempUser.longitude = formValues.longitude;
  tempUser.zoom = formValues.zoom;
  tempUser.expirationTime = formValues.expirationTime;
  tempUser.deviceLimit = formValues.deviceLimit;
  tempUser.userLimit = formValues.userLimit;
  tempUser.administrator = formValues.administrator;
  tempUser.limitCommands = formValues.limitCommands;
  tempUser.fixedEmail = formValues.fixedEmail;
  tempUser.disabled = formValues.disabled;
  tempUser.disableReports = formValues.disableReports;
  tempUser.deviceReadonly = formValues.deviceReadonly;
  tempUser.attributes = formValues.attributes;

  // const localattrib = {};

  // // {"TEST ATTRIB":"12345","web.reportColor":"#FF0000","some other attrib":"1"}

  // // {"0":{"name":"0","value":{"name":"Wife","value":"Sandra"}},"1":{"name":"sdfsdf","value":"sdfsdf"}}

  // attributes.forEach((item) => {
  //   localattrib[item.name] = item.value;
  // });

  // tempUser.attributes = localattrib;

  return JSON.stringify(tempUser);
}
