import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import LogOutButton from "../features/deviceList/logOutButton";
import Toolbar from "@mui/material/Toolbar";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HubIcon from "@mui/icons-material/Hub";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import {
  setUsersDialogOpen,
  setGroupsDialogOpen,
} from "../features/dialogs/dialogStateSlice";

import { selectCurrentUser } from "../features/login/loginSlice";

const BottomAppBar = (props) => {
  const { drawerWidth } = props;

  const dispatch = useDispatch();
  const [canAdd, setcanAdd] = useState(false);
  const currentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    let canAdd = false;
    if (
      currentUser.data.administrator === true ||
      currentUser.data.userLimit !== 0
    ) {
      canAdd = true;
    }
    setcanAdd(canAdd);
  }, [currentUser]);

  const handleUsersDialogOpen = () => {
    dispatch(setUsersDialogOpen(true));
  };
  const handleGroupsDialogOpen = () => {
    dispatch(setGroupsDialogOpen(true));
  };

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "primary.dark",
        top: "auto",
        bottom: 0,
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar
        sx={{
          width: "100%",
          backgroundColor: "primary.dark",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            justifyContent: "flex-end",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Tooltip title="Users">
                <span>
                  <IconButton
                    disabled={!canAdd}
                    onClick={handleUsersDialogOpen}
                    sx={{
                      ":hover": {
                        bgcolor: "primary.light", // theme.palette.primary.main
                        color: "primary.dark",
                      },
                    }}
                    aria-label="open drawer"
                    edge="end"
                  >
                    <PeopleAltIcon
                      style={{
                        opacity: canAdd ? 1 : 0.5,
                        color: "#fff",
                      }}
                      sx={{ fontSize: 40, pr: 0, mr: 0 }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Vehicle Groups">
                <span>
                  <IconButton
                    disabled={!canAdd}
                    onClick={handleGroupsDialogOpen}
                    sx={{
                      ":hover": {
                        bgcolor: "primary.light", // theme.palette.primary.main
                        color: "primary.dark",
                      },
                    }}
                    aria-label="open drawer"
                    edge="end"
                  >
                    <HubIcon
                      sx={{ fontSize: 35, mt: 0.3, pr: 0, mr: 0 }}
                      style={{
                        opacity: canAdd ? 1 : 0.5,
                        color: "#fff",
                      }}
                    />
                  </IconButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <LogOutButton></LogOutButton>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps.device, nextProps.device)) {
    return true;
  }

  return false; // donot re-render
};

export default memo(BottomAppBar, areEqual);
