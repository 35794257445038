import React, { memo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Button from "@mui/material/Button";

import Typography from "@mui/material/Typography";

import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";

import { logoutAsync, selectToken } from "../login/loginSlice";

const LogOutButton = () => {
  const dispatch = useDispatch();

  const usertoken = useSelector(selectToken);

  // const handleLogout = async (event, token) => {
  //   try {
  //     alert(token);
  //     await dispatch(logoutAsync(token)).unwrap();
  //   } catch (err) {}
  // };

  const handleLogout = async () => {
    try {
      await dispatch(logoutAsync(usertoken)).unwrap();
    } catch (err) {}
  };

  return (
    <Tooltip title="Log Out">
      <span>
        <IconButton
          onClick={(event, usertoken) => {
            handleLogout(usertoken);
          }}
          sx={{
            ":hover": {
              bgcolor: "primary.light", // theme.palette.primary.main
              color: "primary.dark",
            },
          }}
          aria-label="open drawer"
          edge="end"
        >
          <PowerSettingsNewIcon
            sx={{ fontSize: 40, pr: 0, mr: 0 }}
            style={{
              color: "#fff",
            }}
          />
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default LogOutButton;
