import React from "react";
import { useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";

import { toast } from "react-toastify";
import _ from "lodash";

import { deleteDeviceAsync } from "../system/deviceSlice";

import { removeListDevice } from "../deviceList/deviceListSlice";

import { useTheme } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  root: {
    "&+.MuiDialogContent-root": {
      paddingTop: "10px !important",
    },
  },
  "& MuiDialog-paper": {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  "& .MuiTypography-h6": {
    paddingRight: "0px",
  },
}));

const DeviceConfirmationDialog = (props) => {
  const { open, handleClose, device } = props;

  const dispatch = useDispatch();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleSubmit = async () => {
    try {
      const response = await dispatch(deleteDeviceAsync(device)).unwrap();

      if (response.status >= 200 || response.status <= 299) {
        toast.success("Operation Successfull.");
        dispatch(removeListDevice(device.id));
        handleClose();
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleLocalClose = async () => {
    handleClose();
  };

  return (
    <div>
      <StyledDialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="Add Device"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            padding: 1,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.contrastText,
            fontSize: "1em",
          }}
        >
          {"Remove Device"}
        </DialogTitle>
        <DialogContent sx={{ padding: 1 }}>
          <form variant="standard" sx={{ m: 1, minWidth: 120 }}>
            <fieldset className="fieldSetInfoRed">
              <DialogContentText>
                Once a device is removed it would need to be manually re-added.
                Are you sure you want to proceed?
              </DialogContentText>
            </fieldset>
            <DialogActions>
              <Button autoFocus onClick={handleSubmit}>
                Yes
              </Button>
              <Button autoFocus onClick={handleLocalClose}>
                No
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </StyledDialog>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps, nextProps)) {
    return true;
  }

  return false; // donot re-render
};

export default React.memo(DeviceConfirmationDialog, areEqual);
