export function deviceFormToJson(formValues, attributes, device) {
  const tempdevice = {};

  // [
  //   {
  //     type: "Numeric",
  //     name: "33333",
  //     value: 33333,
  //   },
  //   {
  //     type: "Text",
  //     name: "aaaaaa",
  //     value: "aaaaaa",
  //   },
  // ]
  tempdevice.id = device ? device.id : 0;
  tempdevice.name = formValues.name;
  tempdevice.uniqueId = formValues.identifier;

  tempdevice.groupId = formValues?.groupId;

  tempdevice.phone = formValues?.phone;
  tempdevice.model = formValues?.model;
  tempdevice.contact = formValues?.contact;
  tempdevice.disabled = formValues?.disabled;
  const localattrib = {};

  // {"TEST ATTRIB":"12345","web.reportColor":"#FF0000","some other attrib":"1"}

  // {"0":{"name":"0","value":{"name":"Wife","value":"Sandra"}},"1":{"name":"sdfsdf","value":"sdfsdf"}}

  attributes.forEach((item) => {
    localattrib[item.name] = item.value;
  });

  tempdevice.attributes = localattrib;

  return JSON.stringify(tempdevice);
}
