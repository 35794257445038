import React, { useState } from "react";
import { useSelector } from "react-redux";

import ListItemButton from "@mui/material/ListItemButton";
import DeviceLogo from "../shared/DeviceLogo";
import Moment from "moment";
import Box from "@mui/material/Box";
import { alpha, useTheme } from "@mui/material/styles";
import styles from "./DeviceItem.module.css";
import { selectSelectedDeviceGroup, selectPosition } from "./deviceListSlice";

const DeviceItem = (props) => {
  const {
    item,
    index,
    style,
    isSelected,
    handleListItemClick,
    handleDeviceListContextMenu,
  } = props;

  //const c = useSelector(selectSelectedDeviceGroup(item?.groupId));

  const theme = useTheme();

  const position = useSelector(selectPosition(item.id));

  let listItemBackgroundColour = alpha(theme.palette.primary.dark, 0.05);
  if (item.status === "online") {
    listItemBackgroundColour = alpha(theme.palette.success.light, 1);
  }

  return (
    <>
      <ListItemButton
        selected={isSelected}
        onClick={(event) => handleListItemClick(event, item)}
        onContextMenu={(event) => handleDeviceListContextMenu(event, item)}
        divider
        className="DeviceListItem"
        style={style}
        sx={{
          backgroundColor: alpha(theme.palette.primary.dark, 0.05),
          borderRight: `5px solid ${listItemBackgroundColour}`,
          borderLeft: `5px solid ${alpha(theme.palette.primary.dark, 0.05)}`,
          "& .MuiTouchRipple-child": {
            backgroundColor: theme.palette.primary.dark,
          },
          "&.Mui-selected": {
            backgroundColor: alpha(theme.palette.primary.dark, 0.25),
            borderLeft: `5px solid ${theme.palette.primary.dark}`,
            pl: `calc(16px - 5px)`,
          },
          "&.Mui-selected:hover": {
            backgroundColor: alpha(theme.palette.primary.dark, 0.25),
            borderLeft: `5px solid ${theme.palette.primary.dark}`,
          },
          "&:hover": {
            backgroundColor: alpha(theme.palette.primary.dark, 0.15),
          },
        }}
      >
        <Box className="DeviceListItem">
          <Box className={styles.deviceLogo}>
            <DeviceLogo status={item.status} />
          </Box>

          <span className={styles.heading}>
            {item.name}
            {item.contact && (
              <span className={styles.contact}>
                &nbsp;&nbsp;({item.contact})
              </span>
            )}
          </span>
          <span className={styles.group}>{item.groupName}</span>
          <span className={styles.lastUpdate}>
            {Moment(item.lastUpdate).format("DD/MM/yyyy HH:mm:ss")}
          </span>
          <span className={styles.battery}>
            {item?.attributes?.ISWIRED === "1"
              ? "Wired " +
                (position?.attributes.battery
                  ? position?.attributes?.battery?.toFixed(2) + "V"
                  : "")
              : "Battery " +
                (position?.attributes.battery
                  ? position?.attributes?.battery?.toFixed(2) + "V"
                  : "")}
          </span>
        </Box>
      </ListItemButton>
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { children: prevChildren, ...restOfPrevProps } = prevProps;
  const { children: nextChildren, ...restOfNextProps } = nextProps;

  if (prevProps.isSelected !== nextProps.isSelected) {
    return false;
  }
  if (prevProps.name !== nextProps.name) {
    return false;
  }
  if (prevProps.contact !== nextProps.contact) {
    return false;
  }
  if (prevProps.positionId !== nextProps.positionId) {
    return false;
  }
  if (prevProps.item.status !== nextProps.item.status) {
    return false;
  }
  if (prevProps.item.groupName !== nextProps.item.groupName) {
    return false;
  }

  if (prevProps.item.lastUpdate !== nextProps.item.lastUpdate) {
    return false; // will re-render
  }

  return false; // donot re-render
};

export default React.memo(DeviceItem, areEqual);
//export default DeviceItem;
