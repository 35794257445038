import React, { useState, useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { MapProvider } from "react-map-gl";
import Box from "@mui/material/Box";
import SocketController from "./services/socketController";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

import LoginPage from "./layout/LoginPage";
import MapPage from "./layout/MapPage";
import PersistLogin from "./features/common/persistLogin";

import DialogControls from "./features/dialogs/dialogControls";

import { useSelector } from "react-redux";
import { selectCurrentUser } from "./features/login/loginSlice";
import { StyledEngineProvider } from "@mui/material/styles";

import { ThemeProvider, createTheme } from "@mui/material/styles";
import { selectPortalTheme } from "./features/login/loginSlice";

import "./App.css";
import "mapbox-gl/dist/mapbox-gl.css";
// import "maplibre-gl/dist/maplibre-gl.css";
import "react-toastify/dist/ReactToastify.css";

import { LicenseInfo } from "@mui/x-date-pickers-pro";

LicenseInfo.setLicenseKey(
  "cd6648c333402a66bf9ce738c8d64fb2T1JERVI6NDMwMTcsRVhQSVJZPTE2ODMyMTQ2ODcwMDAsS0VZVkVSU0lPTj0x"
);

// require("default-passive-events");

// const portalTheme = {
//   palette: {
//     primary: {
//       dark: "#16133f",
//       main: "#2f2989",
//       light: "#a19de2",
//     },
//   },
//   secondary: {
//     // dark: will be calculated from palette.secondary.main,
//     contrastText: "#6962d0",
//   },
//   typography: {
//     allVariants: {
//       color: "black",
//     },
//     h4: {
//       color: "white",
//     },
//   },
// };

// const portalTheme = {
//   palette: {
//     primary: {
//       dark: "#16133f",
//       main: "#2f2989",
//       light: "#a19de2",
//     },
//   },
//   secondary: {
//     // dark: will be calculated from palette.secondary.main,
//     contrastText: "#6962d0",
//   },
//   typography: {
//     allVariants: {
//       color: "black",
//     },
//     h4: {
//       color: "white",
//     },
//   },
// };

//console.log(theTheme2);

function App() {
  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener("resize", handleWindowResize, { passive: true });

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const portalTheme = useSelector(selectPortalTheme);
  const appTheme = createTheme(portalTheme);

  const currentUser = useSelector(selectCurrentUser);

  return (
    <ThemeProvider theme={appTheme} key="app">
      <PersistLogin>
        <ToastContainer />
        <StyledEngineProvider injectFirst>
          <Box className="App">
            {!currentUser ? (
              <LoginPage />
            ) : (
              <MapProvider>
                <SocketController />
                <MapPage />
                <DialogControls height={windowSize[1]} />
              </MapProvider>
            )}
          </Box>
        </StyledEngineProvider>
      </PersistLogin>
    </ThemeProvider>
  );
}

export default App;
