import http from "../../services/httpService";
import config from "../../config.json";
import Qs from "qs";

export async function geoCode(coords) {
  const response = await http.get(
    config.apiUrl +
      `/server/geocode?latitude=${coords.latitude}&longitude=${coords.longitude}`
  );
  return response;
}
