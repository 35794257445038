import http from "../../services/httpService";
import config from "../../config.json";

// Get list of commands for this device
export async function getDeviceCommands(deviceId) {
  const response = await http.get(
    config.apiUrl + `/commands/send?deviceId=${deviceId}`
  );
  return response;
}

export async function sendDeviceCommand(data) {
  const commandObject = {};

  commandObject.id = data.fullitem.id;
  commandObject.deviceId = data.deviceId;
  commandObject.description = data.fullitem.description;
  commandObject.type = data.fullitem.type;
  commandObject.textChannel = data.fullitem.textChannel;
  commandObject.attributes = data.fullitem.attributes;

  let jsonString = JSON.stringify(commandObject);

  const response = await http.post(
    config.apiUrl + `/commands/send`,
    jsonString,
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}
