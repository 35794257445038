import http from "../../services/httpService";
import config from "../../config.json";

export async function getCommandsLinked(user) {
  const response = await http.get(
    config.apiUrl + `/commands?userId=${user.id}`,
    user,
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}

export async function getCommandsAll(user) {
  const response = await http.get(config.apiUrl + `/commands?all=true`, user, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      "Content-Type": "application/json",
    },
  });

  return response;
}
