import React, { useState, useReducer, useEffect, memo } from "react";
import Moment from "moment";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import _ from "lodash";

import { useContextMenu } from "react-contexify";

import { sendDeviceCommandAsync } from "../deviceList/deviceListSlice";

import ContextMenu from "../common/contextifyMenu/ContextMenu";

import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedId,
  select,
  selectSingleId,
  selectSelectedId,
  selectSelectedIds,
} from "../deviceList/deviceListSlice";

import { FixedSizeList as List } from "react-window";
import { useTheme } from "@mui/material/styles";
import DeviceItem from "./DeviceItem";

import "react-contexify/dist/ReactContexify.css";

const ItemRenderer = ({ index, data, style }) => {
  const item = data.devices[index];

  return (
    <DeviceItem
      item={item}
      key={item.key}
      style={style}
      isSelected={
        data.selectedIds ? data.selectedIds.indexOf(item.id) > -1 : false
      }
      handleListItemClick={data.handleListItemClick}
      handleDeviceListContextMenu={data.handleDeviceListContextMenu}
    ></DeviceItem>
  );
};

const ListComponent = (props) => {
  const dispatch = useDispatch();

  const selectedId = useSelector(selectSelectedId);
  const selectedIds = useSelector(selectSelectedIds);

  const MENU_ID = "menu-id";
  const { show } = useContextMenu({
    id: MENU_ID,
  });

  const theme = useTheme();
  const { devices, drawerWidth, controlHeight, onClose } = props;

  const handleListItemClick = (event, item) => {
    dispatch(setSelectedId(item.id));

    if (event.ctrlKey) {
      dispatch(select(item.id));
    } else {
      dispatch(selectSingleId(item.id));
    }
    //Used to close list on mobile.
    if (onClose) {
      onClose();
    }

    if (item.positionId === 0) {
      toast.info("This device has not connected yet.");
    }
  };

  const handleDeviceListContextMenu = async (event, item) => {
    event.preventDefault();

    dispatch(setSelectedId(item.id));

    if (event.ctrlKey) {
      dispatch(select(item.id));
    } else {
      dispatch(selectSingleId(item.id));
    }

    if (item.positionId === 0) {
      toast.info("This device has not connected yet.");
    }

    show(event);
  };

  async function handleItemClick({ event, data }) {
    // id,
    // deviceId,
    // description,
    // attributes
    try {
      const response = await dispatch(sendDeviceCommandAsync(data)).unwrap();

      switch (response.statusText) {
        case "OK":
          toast.success(
            "The '" +
              response.data.description +
              "' command has been queued for deliver to the device."
          );
          break;
        case "Accepted":
          toast.success(
            "The '" +
              response.data.description +
              "' command has been queued for deliver to the device."
          );
          break;
        default:
          toast.success(
            "The '" +
              response.data.description +
              "' command was unable to be sent."
          );
          break;
      }
    } catch (err) {
      toast.success("The command was unable to be sent.");
    }
  }

  return (
    <>
      {devices ? (
        <div>
          <List
            height={controlHeight}
            width={drawerWidth}
            itemSize={50}
            itemCount={devices.length}
            itemData={{
              devices,
              selectedIds,
              handleListItemClick,
              handleDeviceListContextMenu,
            }}
          >
            {ItemRenderer}
          </List>
        </div>
      ) : null}
      <ContextMenu
        menuId={MENU_ID}
        handleItemClick={handleItemClick}
        selectedId={selectedId}
      />
    </>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps, nextProps)) {
    return true;
  }

  return false; //  re-render
};

export default React.memo(ListComponent, areEqual);
