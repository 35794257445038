import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import _ from "lodash";
import "yup-phone";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Grid } from "@mui/material";

import { red } from "@mui/material/colors";
import { XLSX } from "sheetjs-style";
import Box from "@mui/material/Box";
import DialogActions from "@mui/material/DialogActions";
import Tooltip from "@mui/material/Tooltip";
import { Label } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { userFormToJson } from "../../app/converters/userConverters";
// import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
// import { DesktopDateRangePicker } from "@mui/x-date-pickers-pro/DesktopDateRangePicker";
import { toast } from "react-toastify";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { truncate } from "lodash";
import FormLabel from "@mui/material/FormLabel";
import { addUserAsync, updateUserAsync } from "../system/userSlice";
import {
  addListUser,
  updateListUser,
  setSelectedUser,
} from "../userList/userListSlice";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";

const UserScreen = (props) => {
  const { user, handleClose, setHasChanges, hasChanges } = props;

  const dispatch = useDispatch();

  const newRecord = user.newRecord === false ? false : true;

  const [attributes, setAttributes] = React.useState([]);
  const [attribOpen, setAttribOpen] = React.useState(false);

  const addAttribute = () => {
    const attribute = {};

    attribute.name = formik.values.attribName;
    attribute.value = formik.values.attribValue;

    formik.values.attribName = "";
    formik.values.attribValue = "";

    setAttributes([...attributes, attribute]);
  };

  const removeAttribute = (attribute) => {
    setAttributes(attributes.filter((item) => item !== attribute));
  };

  const attributesOk = () => {
    if (
      formik.values.attribName === undefined ||
      formik.values.attribName === "" ||
      formik.values.attribValue === undefined ||
      formik.values.attribValue === ""
    ) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (formValues, actions) => {
    try {
      const userToEdit = userFormToJson(formValues, attributes, user);

      let response = {};
      let isNew = false;

      if (user && Object.keys(user).length !== 0) {
        response = await dispatch(updateUserAsync(userToEdit)).unwrap();
      } else {
        isNew = true;
        response = await dispatch(addUserAsync(userToEdit)).unwrap();
      }

      const newUser = _.cloneDeep(response.data);

      if (response.status === 200) {
        toast.success("Operation Successfull.");

        if (isNew) {
          dispatch(addListUser(newUser));
        } else {
          dispatch(updateListUser(newUser));
        }

        let flagedItem = { ...newUser, newRecord: false };

        dispatch(setSelectedUser(flagedItem));
        formik.values.password = "";
        formik.initialValues = formik.values;
        setHasChanges(false);
      }
    } catch (err) {
      if (err.message === "Identifier already in use!") {
        actions.setErrors({
          identifier: err.message,
        });
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleLocalClose = async () => {
    handleClose();
  };

  const validationSchema = yup.object({
    name: yup.string("Enter a name").required("Name is required"),
    phone: yup.string("Enter a telephone number"),
    email: yup.string("Enter an email").required("Email is required"),
    newRecord: yup.boolean(),
    password: yup.string().when([], {
      is: () => newRecord === true,
      then: yup.string().required("Password is required."),
      otherwise: yup.string().notRequired(),
    }),
    latitude: yup.number().typeError("This must be a number"),
    longitude: yup.number().typeError("This must be a number"),
    zoom: yup.number().typeError("This must be a number"),
    expirationTime: yup
      .date()
      .nullable(true)
      .typeError("Needs to be a valid date.")
      .transform((curr, orig) => (orig === "" ? null : curr)),
    deviceLimit: yup.number().typeError("This must be a number."),
    userLimit: yup.number().typeError("This must be a number"),
    administrator: yup.boolean(),
    limitCommands: yup.boolean(),
    fixedEmail: yup.boolean(),
    disabled: yup.boolean(),
    disableReports: yup.boolean(),
    deviceReadonly: yup.boolean(),
    attribName: yup.string(),
    attribValue: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: user.name ? user.name : "",
      phone: user.phone ? user.phone : "",
      email: user.email ? user.email : "",
      password: user.password ? user.password : "",
      latitude: user.latitude ? user.latitude : "",
      longitude: user.longitude ? user.longitude : "",
      zoom: user.zoom | (user.zoom === 0) ? user.zoom : "",
      expirationTime: user.expirationTime ? user.expirationTime : null,
      deviceLimit:
        user.deviceLimit | (user.deviceLimit === 0) ? user.deviceLimit : "",
      userLimit: user.userLimit | (user.userLimit === 0) ? user.userLimit : "",
      administrator: user.administrator ? user.administrator : false,
      limitCommands: user.limitCommands ? user.limitCommands : false,
      fixedEmail: user.fixedEmail ? user.fixedEmail : false,
      disabled: user.disabled ? user.disabled : false,
      disableReports: user.disableReports ? user.disableReports : false,
      deviceReadonly: user.deviceReadonly ? user.deviceReadonly : false,
      attribName: "",
      attribValue: "",
      attributes: user.attributes ? user.attributes : {},
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    initialTouched: {
      name: true,
      phone: true,
      email: true,
      password: true,
      latitude: true,
      longitude: true,
      zoom: true,
      expirationTime: true,
      deviceLimit: true,
      userLimit: true,
      administrator: true,
      limitCommands: true,
      fixedEmail: true,
      disabled: true,
      disableReports: true,
      deviceReadonly: true,
      attribName: true,
      attribValue: true,
    },
    initialErrors: user
      ? {}
      : {
          name: "Required",
          email: "Required",
          password: "Required",
        },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const initAttributeValues = () => {
    if (user && Object.keys(user).length !== 0) {
      const localAttributes = [];

      Object.entries(user.attributes).forEach(([key, value]) => {
        const attribute = {};

        attribute.name = key;
        attribute.value = value;
        localAttributes.push(attribute);
      });

      setAttributes([...localAttributes]);
    } else {
      setAttributes([]);
    }
  };

  const handleLocalReset = async () => {
    formik.resetForm();

    initAttributeValues();

    formik.setTouched({}, true);
  };

  useEffect(() => {
    initAttributeValues();

    //setAttribOpen(false);
  }, [user]);

  useEffect(() => {
    setHasChanges(!_.isEqual(formik.initialValues, formik.values));
  }, [formik.values]);

  // useEffect(() => {
  //   console.log("formik.values.attributes", formik.values.attributes);
  // }, [formik.values.attributes]);

  useEffect(() => {
    const localattrib = {};

    attributes.forEach((item) => {
      localattrib[item.name] = item.value;
    });

    formik.setFieldValue("attributes", localattrib);
  }, [attributes]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          p: 0,
        }}
      >
        <Box component="fieldset">
          <legend>Required</legend>
          <Grid container wrap="wrap" spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                id="phone"
                label="Phone"
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
                helperText={formik.touched.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                id="email"
                label="Email"
                type="text"
                fullWidth
                autoComplete="username"
                variant="standard"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                id="password"
                label="Password"
                type="password"
                fullWidth
                autoComplete="current-password"
                variant="standard"
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="fieldset">
          <legend>Initial Location (Optional)</legend>
          <Grid container wrap="wrap" spacing={2}>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                id="latitude"
                label="Latitude"
                type="number"
                fullWidth
                variant="standard"
                value={formik.values.latitude}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.latitude && Boolean(formik.errors.latitude)
                }
                helperText={formik.touched.latitude && formik.errors.latitude}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                id="longitude"
                label="Longitude"
                type="number"
                fullWidth
                variant="standard"
                value={formik.values.longitude}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.longitude && Boolean(formik.errors.longitude)
                }
                helperText={formik.touched.longitude && formik.errors.longitude}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                id="zoom"
                label="Zoom"
                type="number"
                fullWidth
                variant="standard"
                value={formik.values.zoom}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.zoom && Boolean(formik.errors.zoom)}
                helperText={formik.touched.zoom && formik.errors.zoom}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="fieldset">
          <legend>User Limits (Optional)</legend>
          <Grid container wrap="wrap" spacing={2}>
            <Grid item xs={12} md={4}>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <>
                  <LocalizationProvider
                    dateAdapter={AdapterMoment}
                    localeText={{ start: "Start Date", end: "End Date" }}
                  >
                    <DatePicker
                      disablePast
                      disableMaskedInput
                      inputFormat="DD/MM/YYYY"
                      // value={reportDate}
                      // onChange={(newValue) => {
                      //   setReportDate(newValue);
                      // }}
                      value={
                        formik.values.expirationTime !== ""
                          ? formik.values.expirationTime
                          : null
                      }
                      onChange={(value) => {
                        formik.setFieldValue("expirationTime", value);
                      }}
                      error={
                        formik.touched.expirationTime &&
                        Boolean(formik.errors.expirationTime)
                      }
                      onBlur={formik.handleBlur}
                      renderInput={(startProps) => (
                        <TextField
                          {...startProps}
                          margin="dense"
                          id="expirationTime"
                          label="Account Expiration"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          variant="standard"
                          helperText={formik.errors.expirationTime}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                id="deviceLimit"
                label="Device Limit"
                type="number"
                fullWidth
                variant="standard"
                value={formik.values.deviceLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.deviceLimit &&
                  Boolean(formik.errors.deviceLimit)
                }
                helperText={
                  formik.touched.deviceLimit && formik.errors.deviceLimit
                }
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                margin="dense"
                id="userLimit"
                label="User Limit"
                type="number"
                fullWidth
                variant="standard"
                value={formik.values.userLimit}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.userLimit && Boolean(formik.errors.userLimit)
                }
                helperText={formik.touched.userLimit && formik.errors.userLimit}
              />
            </Grid>
          </Grid>
        </Box>
        <Box component="fieldset">
          <legend>User Permissions (Optional)</legend>
          <Grid container wrap="wrap" spacing={2}>
            <Grid item xs={6} sm={4} xl={2}>
              <Tooltip title="An administrator is able to view and edit all settings on this protal, including locking other administrators out. Use with care.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.administrator}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Administrator"
                  onChange={formik.handleChange("administrator")}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={4} xl={2}>
              <Tooltip title="The limit commands function limits a user to commands spacifically assigned to them. No custom commands will be allowed.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.limitCommands}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Limit Commands"
                  onChange={formik.handleChange("limitCommands")}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={4} xl={2}>
              <Tooltip title="The user will not be allowed to change their own email address.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.fixedEmail}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Fixed Email"
                  onChange={formik.handleChange("fixedEmail")}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={4} xl={2}>
              <Tooltip title="The user account is completely disabled.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.disabled}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Disabled"
                  onChange={formik.handleChange("disabled")}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={6} sm={4} xl={2}>
              <Tooltip title="The user will not be able to generate reports.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.disableReports}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Disable Reports"
                  onChange={formik.handleChange("disableReports")}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
            </Grid>

            {/* : true,
      : true,
      : true,
      : true,
      deviceReadonly: true, */}
            <Grid item xs={6} sm={4} xl={2}>
              <Tooltip title="The user will not be able add or remove devices or send commands.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formik.values.deviceReadonly}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    />
                  }
                  label="Device Readonly"
                  onChange={formik.handleChange("deviceReadonly")}
                  onBlur={formik.handleBlur}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>

        <fieldset className="fieldSetRequiredAddDevice">
          <legend>
            Attributes
            <IconButton
              className="legendExpander"
              sx={{
                width: "20px",
                height: "10px",
              }}
              onClick={() => setAttribOpen(!attribOpen)}
            >
              {attribOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </legend>

          <Collapse
            in={attribOpen}
            timeout="auto"
            orientation="vertical"
            unmountOnExit
            sx={{ padding: "0.05px" }}
          >
            <List
              sx={{
                width: "100%",

                bgcolor: "background.paper",
              }}
            >
              {attributes.map((attribute, index) => (
                <ListItem
                  key={index}
                  sx={{ paddingLeft: 0, paddingRight: 0 }}
                  secondaryAction={
                    <IconButton
                      aria-label="comment"
                      onClick={() => removeAttribute(attribute)}
                    >
                      <DeleteForeverIcon />
                    </IconButton>
                  }
                >
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <FormLabel>{`${attribute.name}`}</FormLabel>
                    </Grid>
                    <Grid item xs={6}>
                      <FormLabel>{`${attribute.value}`}</FormLabel>
                    </Grid>
                    <Grid item xs={1}></Grid>
                  </Grid>
                </ListItem>
              ))}
            </List>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <TextField
                    id="attribName"
                    name="attribName"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    label="Name"
                    value={formik.values.attribName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.attribName &&
                      Boolean(formik.errors.attribName)
                    }
                  ></TextField>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="attribValue"
                    name="attribValue"
                    margin="dense"
                    fullWidth
                    variant="standard"
                    label="Value"
                    value={formik.values.attribValue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.attribValue &&
                      Boolean(formik.errors.attribValue)
                    }
                    helperText={
                      formik.touched.attribValue && formik.errors.attribValue
                    }
                  ></TextField>
                </Grid>

                <Grid item xs={8}>
                  <Button
                    disabled={attributesOk() ? false : true}
                    onClick={addAttribute}
                  >
                    Add
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </fieldset>

        <DialogActions>
          <Button
            autoFocus
            disabled={!formik.isValid || !hasChanges}
            type="submit"
          >
            {newRecord == true ? "Add New User" : "Update User"}
          </Button>

          {hasChanges ? (
            <Button autoFocus onClick={handleLocalReset}>
              Reset
            </Button>
          ) : (
            <Button autoFocus onClick={handleLocalClose}>
              Close
            </Button>
          )}
        </DialogActions>
      </Box>
    </form>
  );
};

export default UserScreen;
