import React from "react";
import _ from "lodash";
import moment from "moment";
import { useMap, Source, Layer } from "react-map-gl";

import { ConvertDegrees, Convert_KN_MPH } from "../common/ConvertDegrees";

import { getBounds } from "../common/getBounds";

const MapTrace = (props) => {
  const { mainMap } = useMap();
  const { deviceTrace } = props;

  // if (deviceTrace.length == 0) {
  //   return <></>;
  // }

  //////////////////////////////////////////////////////////////////////////////////////////
  //Centre MAP
  //////////////////////////////////////////////////////////////////////////////////////////

  if (mainMap && deviceTrace?.length > 0) {
    var zoom = mainMap.getZoom();

    const options = {
      padding: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      linear: true,
      maxZoom: zoom,
    };

    mainMap.fitBounds(getBounds(deviceTrace), options);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //GEOJASON LINE
  //////////////////////////////////////////////////////////////////////////////////////////

  const geojsonLine = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {},
        geometry: {
          coordinates: [],
          type: "LineString",
        },
      },
    ],
  };
  const geojsonLineMarkers = {
    type: "FeatureCollection",
    features: [],
  };

  for (let i = 0; i < deviceTrace.length; i++) {
    const detail = [deviceTrace[i]["longitude"], deviceTrace[i]["latitude"]];
    geojsonLine["features"][0].geometry.coordinates.push(detail);

    const detailMarker = {
      type: "Feature",
      properties: {
        time: moment(deviceTrace[i]["fixTime"]).format("DD/MM/yyyy hh:mm:ss"),
        rotation: deviceTrace[i]["course"],
        heading: ConvertDegrees(deviceTrace[i]["course"]),
        speed: Convert_KN_MPH(deviceTrace[i]["speed"]),
      },
      geometry: {
        coordinates: [deviceTrace[i]["longitude"], deviceTrace[i]["latitude"]],
        type: "Point",
      },
    };

    geojsonLineMarkers["features"].push(detailMarker);
  }

  const layerLineStyle = {
    id: "route",
    type: "line",
    layout: {
      "line-join": "round",
      "line-cap": "round",
    },
    paint: {
      "line-color": "#47b80b",
      "line-width": 5,
    },
  };

  const layerLineMarkers = {
    id: "lineMarkers",
    type: "symbol",
    scale: 1,
    layout: {
      "icon-image": "devicemarker",
      "icon-size": 1,
      "icon-anchor": "top",
      "icon-rotation-alignment": "map",
      "icon-rotate": ["get", "rotation"],
      "icon-allow-overlap": false,
    },
  };

  //////////////////////////////////////////////////////////////////////////////////////////
  //GEOJASON START END
  //////////////////////////////////////////////////////////////////////////////////////////

  const geoJasonStartEnd = {
    type: "FeatureCollection",
    features: [],
  };

  const detailStartCoordinates =
    deviceTrace.length > 0
      ? [deviceTrace[0]["longitude"], deviceTrace[0]["latitude"]]
      : [];

  const detailStart = {
    type: "Feature",
    properties: { image: "start" },
    geometry: {
      coordinates: detailStartCoordinates ? detailStartCoordinates : null,
      type: "Point",
    },
  };

  const detailEndCoordinates =
    deviceTrace.length > 0
      ? [
          deviceTrace[deviceTrace.length - 1]["longitude"],
          deviceTrace[deviceTrace.length - 1]["latitude"],
        ]
      : [];

  const detailEnd = {
    type: "Feature",
    properties: { image: "finish" },
    geometry: {
      coordinates: detailEndCoordinates ? detailEndCoordinates : null,
      type: "Point",
    },
  };

  if (detailStartCoordinates.length > 0) {
    geoJasonStartEnd["features"].push(detailStart);
  }

  if (detailEndCoordinates.length > 0) {
    geoJasonStartEnd["features"].push(detailEnd);
  }

  const layerStartEndStyle = {
    id: "startEnd",
    type: "symbol",
    scale: 1,
    layout: {
      "icon-image": ["get", "image"],
      "icon-size": 1,
      "icon-anchor": "bottom",
      "icon-rotation-alignment": "map",
      "icon-rotate": 0,
      "icon-allow-overlap": false,
    },
  };

  return (
    <>
      <Source id="my-line" type="geojson" data={geojsonLine}>
        <Layer {...layerLineStyle} />
      </Source>
      <Source id="my-line-markers" type="geojson" data={geojsonLineMarkers}>
        <Layer {...layerLineMarkers} />
      </Source>
      <Source id="my-startEnd" type="geojson" data={geoJasonStartEnd}>
        <Layer {...layerStartEndStyle} />
      </Source>
    </>
  );
};

function isPropIsEqual(prevProps, nextProps) {
  if (_.isEqual(prevProps.deviceTrace, nextProps.deviceTrace)) {
    return true;
  }
  return false;
}

export default React.memo(MapTrace, isPropIsEqual);
