import React from "react";
import { styled } from "@mui/system";
import IconButton from "@mui/material/IconButton";
import FavoriteIcon from "@mui/icons-material/Favorite";
import SearchIcon from "@mui/icons-material/Search";
import GradingIcon from "@mui/icons-material/Grading";
import { selectAllIds } from "../deviceList/deviceListSlice";
import HighlightOffTwoToneIcon from "@mui/icons-material/HighlightOffTwoTone";
import ClearIcon from "@mui/icons-material/Clear";
import BackspaceTwoToneIcon from "@mui/icons-material/BackspaceTwoTone";

import { useDispatch } from "react-redux";

import { selectUserFilter, setUserFilter } from "../userList/userListSlice";

const StyledIconButton = styled(IconButton)({
  height: "100%",
  position: "absolute",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 99,
  // Add additional CSS styles here
});

const SearchIconWrapper = (props) => {
  const { hasChanges } = props;
  const dispatch = useDispatch();

  const handleClick = () => {
    // Handle button click event here
    dispatch(setUserFilter(""));
  };

  return (
    <StyledIconButton
      color="primary"
      onClick={handleClick}
      disabled={hasChanges}
    >
      <BackspaceTwoToneIcon />
    </StyledIconButton>
  );
};

export default SearchIconWrapper;
