import { useRef, useEffect } from "react";
import { async } from "./../../login/loginAPI";
import moment from "moment";

export async function useSumerizeTrips(trips) {
  const summarizedData = {};

  summarizedData.trips = [];

  let lastTs = moment("2007-03-01T13:00:00Z");

  trips.forEach((trip, index, array) => {
    summarizedData.trips.push(trip);
  });

  return summarizedData;
}
