import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";

import UsersDialog from "./UsersDialog";
import GroupsDialog from "./GroupsDialog";
import DeviceDialog from "./DeviceDialog";
import DeviceConfirmationDialog from "./deviceConfirmationDialog";
import UsersConfirmationDialog from "./userConfirmationDialog";

import {
  selectDeviceGroups,
  selectSelectedId,
  selectUsers,
  setSelectedUser,
  selectSelectedUser,
  selectSelectedDevice,
} from "../deviceList/deviceListSlice";

import {
  selectUsersDialogOpen,
  selectGroupsDialogOpen,
  setUsersDialogOpen,
  setGroupsDialogOpen,
  setDeviceDialogOpen,
  selectDeviceDialogOpen,
  setDeviceConfirmationDialogOpen,
  setUsersConfirmationDialogOpen,
  selectUsersConfirmationDialogOpen,
  selectDeviceConfirmationDialogOpen,
} from "../dialogs/dialogStateSlice";

import { selectCurrentUser } from "../login/loginSlice";

const DialogControls = (props) => {
  const { height } = props;

  const dispatch = useDispatch();

  const currentUser = useSelector(selectCurrentUser);

  const usersDialogOpen = useSelector(selectUsersDialogOpen);
  const groupsDialogOpen = useSelector(selectGroupsDialogOpen);
  const deviceDialogOpen = useSelector(selectDeviceDialogOpen);

  const deviceConfirmationDialogOpen = useSelector(
    selectDeviceConfirmationDialogOpen
  );
  const usersConfirmationDialogOpen = useSelector(
    selectUsersConfirmationDialogOpen
  );

  const groups = useSelector(selectDeviceGroups);

  const selectedId = useSelector(selectSelectedId);
  const selectedDevice = useSelector(selectSelectedDevice(selectedId));

  const handleUsersDialogOpen = () => {
    dispatch(setUsersDialogOpen(true));
  };
  const handleGroupsDialogOpen = () => {
    dispatch(setGroupsDialogOpen(true));
  };

  const handleGroupsDialogClose = () => {
    dispatch(setGroupsDialogOpen(false));
  };

  const handleDeviceDialogClose = () => {
    dispatch(setDeviceDialogOpen(false));
  };
  const handleDeviceConfirmationDialogClose = () => {
    dispatch(setDeviceConfirmationDialogOpen(false));
  };

  const handleUsersConfirmationDialogClose = () => {
    dispatch(setUsersConfirmationDialogOpen(false));
  };

  return (
    <>
      <UsersDialog
        open={usersDialogOpen}
        currentUser={currentUser}
        groups={groups}
        height={height}
      />
      <GroupsDialog
        open={groupsDialogOpen}
        groups={groups}
        height={height}
        handleClose={handleGroupsDialogClose}
      />
      <DeviceDialog
        open={deviceDialogOpen}
        device={selectedDevice}
        groups={groups}
        handleClose={handleDeviceDialogClose}
      />
      <DeviceConfirmationDialog
        open={deviceConfirmationDialogOpen}
        device={selectedDevice}
        handleClose={handleDeviceConfirmationDialogClose}
      />
      <UsersConfirmationDialog
        open={usersConfirmationDialogOpen}
        handleClose={handleUsersConfirmationDialogClose}
      />
    </>
  );
};

export default DialogControls;
