import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import LogOutButton from "../deviceList/logOutButton";

import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import HubIcon from "@mui/icons-material/Hub";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Typography from "@mui/material/Typography";

import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import { selectCurrentUser } from "../login/loginSlice";

const BottomDialogAppBar = (props) => {
  const { drawerWidth } = props;

  return (
    <AppBar
      position="fixed"
      sx={{
        bgcolor: "primary.dark",
        top: "auto",
        bottom: 0,
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
      }}
    >
      <Toolbar
        sx={{
          width: "100%",
          backgroundColor: "primary.dark",
          justifyContent: "flex-end",
        }}
      ></Toolbar>
    </AppBar>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps.device, nextProps.device)) {
    return true;
  }

  return false; // donot re-render
};

export default memo(BottomDialogAppBar, areEqual);
