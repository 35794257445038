import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  deviceDialogOpen: false,
  deviceConfirmationDialogOpen: false,
  usersConfirmationDialogOpen: false,
  groupsConfirmationDialogOpen: false,
  usersDialogOpen: false,
  groupsDialogOpen: false,
};

export const dialogStateSlice = createSlice({
  name: "dialogState",
  initialState,
  reducers: {
    setDeviceDialogOpen(state, action) {
      state.deviceDialogOpen = action.payload;
    },
    setDeviceConfirmationDialogOpen(state, action) {
      state.deviceConfirmationDialogOpen = action.payload;
    },
    setUsersDialogOpen(state, action) {
      state.usersDialogOpen = action.payload;
    },
    setUsersConfirmationDialogOpen(state, action) {
      state.usersConfirmationDialogOpen = action.payload;
    },
    setGroupsDialogOpen(state, action) {
      state.groupsDialogOpen = action.payload;
    },
    setGroupsConfirmationDialogOpen(state, action) {
      state.groupsConfirmationDialogOpen = action.payload;
    },
  },
});

export const {
  setDeviceDialogOpen,
  setDeviceConfirmationDialogOpen,
  setUsersDialogOpen,
  setUsersConfirmationDialogOpen,
  setGroupsDialogOpen,
  setGroupsConfirmationDialogOpen,
} = dialogStateSlice.actions;

export const selectDeviceDialogOpen = (state) =>
  state.dialogState.deviceDialogOpen;

export const selectDeviceConfirmationDialogOpen = (state) =>
  state.dialogState.deviceConfirmationDialogOpen;

export const selectUsersDialogOpen = (state) =>
  state.dialogState.usersDialogOpen;

export const selectUsersConfirmationDialogOpen = (state) =>
  state.dialogState.usersConfirmationDialogOpen;

export const selectGroupsDialogOpen = (state) =>
  state.dialogState.groupsDialogOpen;

export const selectGroupsConfirmationDialogOpen = (state) =>
  state.dialogState.groupsConfirmationDialogOpen;

export default dialogStateSlice.reducer;
