import React, { useState } from "react";

import styles from "./MapLayerSelector.module.css";

import RoadMap from "../../assets/RoadMap.jpg";
import Satellite from "../../assets/Satellite.jpg";

export default function MapLayerSelector(props) {
  const { StyleChange } = props;

  const [useSatellite, setUseSatellite] = useState(false);

  const handleClick = () => {
    // 👇️ toggle
    setUseSatellite(!useSatellite);

    // RoadMap: "mapbox://styles/mapbox/outdoors-v12",
    // Satellite: "mapbox://styles/mapbox/satellite-streets-v12",

    if (useSatellite) {
      StyleChange("mapbox://styles/mapbox/satellite-streets-v12");
    } else {
      StyleChange("mapbox://styles/mapbox/outdoors-v12");
    }
  };

  return (
    <div
      onClick={handleClick}
      style={{
        background: useSatellite ? `url(${Satellite})` : `url(${RoadMap})`,
      }}
      className={styles.MapSelectorBox}
    >
      <span
        style={{
          color: useSatellite ? `white` : `black`,
        }}
      >
        {useSatellite ? `Satellite` : `Road Map`}
      </span>
    </div>
  );
}
