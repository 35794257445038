import http from "../../services/httpService";
import config from "../../config.json";

export async function getPositions(deviceId, from, to) {
  const response = await http.get(
    config.apiUrl + `/positions?deviceId=${deviceId}&from=${from}&to=${to}`
  );
  return response;
}

export async function getTrips(deviceId, from, to) {
  const response = await http.get(
    config.apiUrl + `/reports/trips/?deviceId=${deviceId}&from=${from}&to=${to}`
  );
  return response;
}

export async function getStops(deviceId, from, to) {
  const response = await http.get(
    config.apiUrl + `/reports/stops/?deviceId=${deviceId}&from=${from}&to=${to}`
  );
  return response;
}
