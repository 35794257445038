import React, { PureComponent } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class UtilizCharts extends PureComponent {
  constructor(props) {
    super(props);
    this.charts = [];
    this.syncHandler = this.syncHandler.bind(this);
  }

  syncHandler(e) {
    var charts = this.charts;
    for (var i = 0; i < charts.length; i++) {
      var chart = charts[i];

      if (!chart.options.axisX) chart.options.axisX = {};

      if (!chart.options.axisY) chart.options.axisY = {};

      if (e.trigger === "reset") {
        chart.options.axisX.viewportMinimum =
          chart.options.axisX.viewportMaximum = null;
        chart.options.axisY.viewportMinimum =
          chart.options.axisY.viewportMaximum = null;

        chart.render();
      } else if (chart !== e.chart) {
        chart.options.axisX.viewportMinimum = e.axisX[0].viewportMinimum;
        chart.options.axisX.viewportMaximum = e.axisX[0].viewportMaximum;

        chart.options.axisY.viewportMinimum = e.axisY[0].viewportMinimum;
        chart.options.axisY.viewportMaximum = e.axisY[0].viewportMaximum;

        chart.render();
      }
    }
  }

  render() {
    var sumData = this.props.sumData;

    var powerData = [
      {
        type: "line",
        color: this.props.mainColour,
        xValueType: "dateTime",
        dataPoints: sumData.powerPos,
      },
    ];

    var powerOptions = {
      data: powerData,
      zoomEnabled: true,
      exportEnabled: false,
      rangeChanged: this.syncHandler,
      animationEnabled: false,

      axisY: {
        labelFontSize: 14,
        labelFontFamily: "Arial",
        interval: 1,
        labelAutoFit: true,
      },
    };

    var ignitionData = [
      {
        type: "stepArea",
        color: this.props.mainColour,
        xValueType: "dateTime",
        dataPoints: sumData.ignitionPos,
      },
    ];

    const ignitionOptions = {
      data: ignitionData,
      zoomEnabled: true,
      exportEnabled: false,
      axisY: {
        labelFontSize: 14,
        labelFontFamily: "Arial",
        minimum: 0,
        maximum: 1,
        interval: 1,
        labelAutoFit: true,

        labelFormatter: function (e) {
          if (e.value >= 0.5) {
            return "ON";
          } else {
            return "OFF";
          }
        },
      },
      axisX: {
        labelFontSize: 14,
        labelFontFamily: "Arial",
        interval: 1,
        labelAutoFit: true,
      },
      rangeChanged: this.syncHandler,
      animationEnabled: false,
      stroke: {
        curve: "stepline",
      },
    };

    var ev = {};
    ev.trigger = "reset";
    this.syncHandler(ev);

    const LineUpMarging = () => {
      var charts = this.charts;
      var doCalc = true;

      for (var i = 0; i < charts.length; i++) {
        if (!charts[i]?.axisY[0]) {
          doCalc = false;
        }
      }
      if (doCalc) {
        var axisYBoundMax = 0;
        for (var i = 0; i < charts.length; i++) {
          axisYBoundMax = Math.max(axisYBoundMax, charts[i].axisY[0].bounds.x2);
        }

        for (var i = 0; i < charts.length; i++) {
          charts[i].axisY[0].set(
            "margin",
            axisYBoundMax -
              (charts[i].axisY[0].bounds.x2 - charts[i].axisY[0].bounds.x1)
          );
        }
      }
    };

    const containerProps1 = {
      width: "100%",
      height: "100%",
    };
    const containerProps2 = {
      width: "100%",
      height: "100%",
    };

    return (
      <div className="chartContainer">
        <div className="ignitionChart">
          <CanvasJSChart
            options={ignitionOptions}
            containerProps={containerProps1}
            onRef={(ref) => {
              this.charts.push(ref);
              LineUpMarging();
            }}
          />
        </div>
        <div className="powerChart">
          <CanvasJSChart
            containerProps={containerProps2}
            options={powerOptions}
            onRef={(ref) => {
              this.charts.push(ref);
              LineUpMarging();
            }}
          />
        </div>
      </div>
    );
  }
}
