import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addUser, updateUser, deleteUser } from "./userAPI";

const initialState = {
  user: null,
  status: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const addUserAsync = createAsyncThunk(
  "user/addUserAsync",
  async (user, { rejectWithValue }) => {
    try {
      const response = await addUser(user);
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.message;

      if (err.response.status === 401) {
        errResponse.message = "the user could not be added!";
      }

      if (
        err.response.status === 400 &&
        err.response.data.includes("Duplicate entry") &&
        err.response.data.includes("uniqueid")
      ) {
        errResponse.message = "Identifier already in use!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const updateUserAsync = createAsyncThunk(
  "user/updateUserAsync",
  async (user, { rejectWithValue }) => {
    try {
      const response = await updateUser(user);
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.message;

      if (err.response.status === 401) {
        errResponse.message = "the User could not be updated!";
      }

      if (
        err.response.status === 400 &&
        err.response.data.includes("Duplicate entry") &&
        err.response.data.includes("uniqueid")
      ) {
        errResponse.message = "Identifier already in use!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const deleteUserAsync = createAsyncThunk(
  "user/deleteUserAsync",
  async (user, { rejectWithValue }) => {
    try {
      const response = await deleteUser(user);
      //The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.message;

      if (err.response.status === 401) {
        errResponse.message = "the User could not be deleted!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(addUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addUserAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload;
      })
      .addCase(addUserAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(updateUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateUserAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.user = action.payload;
      })
      .addCase(updateUserAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(deleteUserAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteUserAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.user = null;
      })
      .addCase(deleteUserAsync.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

// export const {} = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectUser = (state) => state.user;

export const selectUserQueryState = (state) => state.status;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userSlice.reducer;
