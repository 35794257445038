import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { formatNotificationTitle } from "../../app/converters/formatter";
import DialogActions from "@mui/material/DialogActions";
//import { useParams } from "react-router-dom";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Container,
} from "@mui/material";
// import makeStyles from "@mui/styles/makeStyles";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkField from "../common/LinkedFields";
// import { useTranslation } from "../common/components/LocalizationProvider";
// import SettingsMenu from "./components/SettingsMenu";
// import { formatNotificationTitle } from "../common/util/formatter";
// import PageLayout from "../common/components/PageLayout";

import { getGroupsLinked, getGroupsAll } from "../system/groupsApi";
import { getCommandsLinked, getCommandsAll } from "../system/commandsApi";
import {
  getNotificationsLinked,
  getNotificationsAll,
} from "../system/notificationsApi";

const UserConnections = (props) => {
  const { user, handleClose, setHasChanges, hasChanges, groups } = props;

  const dispatch = useDispatch();

  const newRecord = user?.newRecord === false ? false : true;

  return (
    <Box
      sx={{
        p: 0,
      }}
    >
      <Box>
        <LinkField
          user={user}
          endpointAll={getGroupsAll(user)}
          endpointLinked={getGroupsLinked(user)}
          baseId={user?.id}
          keyBase="userId"
          keyLink="groupId"
          label={"Groups This User Has Access To"}
        />
      </Box>
      <Box sx={{ pt: 5 }}>
        <LinkField
          user={user}
          endpointAll={getCommandsAll(user)}
          endpointLinked={getCommandsLinked(user)}
          baseId={user?.id}
          keyBase="userId"
          keyLink="commandId"
          titleGetter={(it) => it.description}
          label={"Commands This User Has Access To"}
        />
      </Box>
      <Box sx={{ pt: 5 }}>
        <LinkField
          user={user}
          endpointAll={getNotificationsAll(user)}
          endpointLinked={getNotificationsLinked(user)}
          baseId={user?.id}
          keyBase="userId"
          keyLink="notificationId"
          titleGetter={(it) => formatNotificationTitle(it, true)}
          label={"Notifications This User Is Subscribed To"}
        />
      </Box>

      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Close
        </Button>
      </DialogActions>
    </Box>
  );
};

export default UserConnections;
