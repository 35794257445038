import React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as Logo } from "../../assets/user.svg";
import { Badge, Avatar } from "@mui/material";

const UserLogo = (props) => {
  const { name, disabled } = props;

  const theme = useTheme();
  const StyledBadgeActive = styled(Badge)(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& .MuiBadge-badge": {
      backgroundColor: `${theme.palette.success.light}`,
      color: `${theme.palette.success.light}`,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const StyledBadgeInactive = styled(Badge)(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& .MuiBadge-badge": {
      backgroundColor: `${theme.palette.error.light}`,
      color: `${theme.palette.error.light}`,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  }));

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  function stringAvatar(name) {
    if (name.trim().includes(" ")) {
      return {
        children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
      };
    } else {
      return { children: name.substring(0, 1) };
    }
  }

  return (
    <>
      {disabled === false ? (
        <StyledBadgeActive
          id="deviceAvatarActive"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          variant="dot"
        >
          <Avatar
            {...stringAvatar(name)}
            sx={{
              width: 1,
              height: 1,
              backgroundColor: alpha(theme.palette.primary.dark, 1),
            }}
          ></Avatar>
        </StyledBadgeActive>
      ) : (
        <StyledBadgeInactive
          id="deviceAvatarActive"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          variant="dot"
        >
          <Avatar
            {...stringAvatar(name)}
            sx={{
              width: 1,
              height: 1,
              backgroundColor: alpha(theme.palette.primary.dark, 1),
            }}
          ></Avatar>
        </StyledBadgeInactive>
      )}
    </>
  );
};

export default UserLogo;
