import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { loginUser, logoutUser, loginWithToken, getToken } from "./loginAPI";
import { decode } from "html-entities";

const initialState = {
  currentUser: null,
  token: null,
  portalName: null,
  portalTheme: {
    palette: {
      primary: {
        dark: "#16133f",
        main: "#2f2989",
        light: "#a19de2",
        verylight: "#f7f7f7",
      },
    },
    secondary: {
      contrastText: "#6962d0",
    },
    typography: {
      allVariants: {
        color: "black",
      },
      h4: {
        color: "white",
      },
    },
  },
  status: "idle",
};

export const loginAsync = createAsyncThunk(
  "login/loginUser",
  async (credData, { rejectWithValue }) => {
    try {
      const response = await loginUser(credData);
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.response.message;

      if (err.response.status === 401) {
        errResponse.message = "Please check login details!";
      }

      return rejectWithValue(errResponse);
    }
  }
);

export const getTokenAsync = createAsyncThunk(
  "login/getToken",
  async (credData, { rejectWithValue }) => {
    try {
      const response = await getToken();
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.response.message;

      if (err.response.status === 401) {
        errResponse.message = "Please check login details!";
      }
      return rejectWithValue(errResponse);
    }
  }
);
export const loginWithTokenAsync = createAsyncThunk(
  "login/loginWithToken",
  async (token, { rejectWithValue }) => {
    try {
      const response = await loginWithToken(token);
      // The value we return becomes the `fulfilled` action payload

      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.message = response.message;
      responseData.data = response.data;
      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.response.message;

      return rejectWithValue(errResponse);
    }
  }
);
export const logoutAsync = createAsyncThunk(
  "login/logoutUser",
  async (token, { rejectWithValue }) => {
    try {
      const response = await logoutUser(token);
      // The value we return becomes the `fulfilled` action payload
      if (response.status === 204) {
        localStorage.removeItem("authtoken");
      }
      return response.data;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.response.message;
      return rejectWithValue(errResponse);
    }
  }
);

export const loginSlice = createSlice({
  name: "login",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(loginAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.currentUser = action.payload;
        state.portalName = action.payload?.data?.attributes?.sysPortalTitle;

        if (action.payload?.data?.attributes?.sysTheme) {
          const json = decode(action.payload?.data?.attributes?.sysTheme);

          const theme = JSON.parse(json);

          state.portalTheme = theme;
        }
      })
      .addCase(loginAsync.rejected, (state, action) => {
        state.status = "rejected";
      })

      .addCase(getTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getTokenAsync.fulfilled, (state, action) => {
        state.token = action.payload;
        state.status = "success";
      })
      .addCase(getTokenAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(loginWithTokenAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(loginWithTokenAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.currentUser = action.payload;
        state.portalName = action.payload?.data?.attributes?.sysPortalTitle;
        if (action.payload?.data?.attributes?.sysTheme) {
          const json = decode(action.payload?.data?.attributes?.sysTheme);
          const theme = JSON.parse(json);
          state.portalTheme = theme;
        }
      })
      .addCase(loginWithTokenAsync.rejected, (state, action) => {
        state.status = "rejected";
      })
      .addCase(logoutAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(logoutAsync.fulfilled, (state, action) => {
        state.status = "success";
        state.token = null;
        state.currentUser = null;
      })
      .addCase(logoutAsync.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

// export const {} = loginSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectCurrentUser = (state) =>
  state?.login ? state.login.currentUser : null;

export const selectPortalName = (state) =>
  state?.login ? state.login.portalName : null;

export const selectPortalTheme = (state) =>
  state?.login ? state.login.portalTheme : null;

export const selectToken = (state) =>
  state?.login?.token ? state.login.token : null;

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const { setToken } = loginSlice.actions;

export default loginSlice.reducer;
