import React from "react";
import _ from "lodash";
import moment from "moment";
import { useMap, Source, Layer } from "react-map-gl";

import { getBoundsForPoint } from "../common/getBounds";

const MapPoint = (props) => {
  const { mainMap } = useMap();
  const { devicePoint } = props;

  //////////////////////////////////////////////////////////////////////////////////////////
  //Centre MAP
  //////////////////////////////////////////////////////////////////////////////////////////

  if (mainMap && devicePoint.id !== -1) {
    var zoom = mainMap.getZoom();

    const options = {
      padding: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      linear: true,
      maxZoom: zoom,
    };

    mainMap.fitBounds(getBoundsForPoint(devicePoint), options);
  }

  //////////////////////////////////////////////////////////////////////////////////////////
  //GEOJASON LINE
  //////////////////////////////////////////////////////////////////////////////////////////

  //////////////////////////////////////////////////////////////////////////////////////////
  //GEOJASON START END
  //////////////////////////////////////////////////////////////////////////////////////////

  const detailPointCoordinates =
    devicePoint.id !== -1 ? [devicePoint.longitude, devicePoint.latitude] : [];

  const detailPoint = {
    type: "Feature",
    properties: {
      image: devicePoint.type,
      startTime: moment(devicePoint.startTime).format("DD/MM/yyyy hh:mm:ss"),
      endTime: moment(devicePoint.endTime).format("DD/MM/yyyy hh:mm:ss"),
      address: devicePoint.address,
      duration: devicePoint.duration,
    },
    geometry: {
      coordinates: detailPointCoordinates ? detailPointCoordinates : null,
      type: "Point",
    },
  };

  const geoJasonPoint = {
    type: "FeatureCollection",
    features: [],
  };

  if (detailPointCoordinates.length > 0) {
    geoJasonPoint["features"].push(detailPoint);
  }

  const layerPointStyle = {
    id: "pointLayer",
    type: "symbol",
    scale: 1,
    layout: {
      "icon-image": ["get", "image"],
      "icon-size": 1,
      "icon-anchor": "bottom",
      "icon-rotation-alignment": "map",
      "icon-rotate": 0,
      "icon-allow-overlap": false,
    },
  };

  return (
    <>
      <Source id="my-Point" type="geojson" data={geoJasonPoint}>
        <Layer {...layerPointStyle} />
      </Source>
    </>
  );
};

function isPropIsEqual(prevProps, nextProps) {
  if (_.isEqual(prevProps.devicePoint, nextProps.devicePoint)) {
    return true;
  }
  return false;
}

export default React.memo(MapPoint, isPropIsEqual);
