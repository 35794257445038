import JourneyTable from "./JourneyTable";
const JourneyHistory = (props) => {
  return (
    <div className="chartContainer">
      {props.SummarizedTrips?.trips?.length > 0 ? (
        <JourneyTable tripData={props.SummarizedTrips} id={props.id} />
      ) : (
        <p>No Trip Data, Get Data.</p>
      )}
    </div>
  );
};
export default JourneyHistory;
