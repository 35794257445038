import React, { useState, useEffect, memo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import { AutoSizer } from "@enykeev/react-virtualized";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { alpha, useTheme } from "@mui/material/styles";
import UserSearch from "./userSearch";
import { FixedSizeList as List } from "react-window";
import Moment from "moment";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// import UserLogo from "../shared/DeviceLogo";

import UserLogo from "./UserLogo";

import styles from "./UserItem.module.css";

import {
  setDeviceDialogOpen,
  setUsersConfirmationDialogOpen,
} from "../dialogs/dialogStateSlice";

import { selectUserFilter, selectSelectedUser } from "./userListSlice";
import { selectCurrentUser } from "../login/loginSlice";

import { ContactlessOutlined } from "@mui/icons-material";
import PersonAddDisabledTwoToneIcon from "@mui/icons-material/PersonAddDisabledTwoTone";
import IconButton from "@mui/material/IconButton";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

const UserList = (props) => {
  const {
    appBarHeight,
    controlHeight,
    users,
    drawerWidth,
    onClose,
    setSelectedUser,
    selectedUser,
    hasChanges,
  } = props;

  const user = useSelector(selectSelectedUser);

  const ItemRenderer = ({ index, data, style }) => {
    const item = data.sortedItems[index];

    const theme = useTheme();

    let listItemBackgroundColour = alpha(theme.palette.primary.dark, 0.05);

    return item ? (
      <Disabled disabled={hasChanges}>
        <ListItemButton
          id={`fixed-size-row-${index}`}
          divider
          className="UserListItem"
          selected={selectedUser?.id ? selectedUser.id === item?.id : false}
          onClick={(event) => handleListItemClick(event, item)}
          style={style}
          sx={{
            backgroundColor: alpha(theme.palette.primary.dark, 0.05),
            borderRight: `5px solid ${listItemBackgroundColour}`,
            borderLeft: `5px solid ${alpha(theme.palette.primary.dark, 0.05)}`,
            "& .MuiTouchRipple-child": {
              backgroundColor: theme.palette.primary.dark,
            },
            "&.Mui-selected": {
              backgroundColor: alpha(theme.palette.primary.dark, 0.25),
              borderLeft: `5px solid ${theme.palette.primary.dark}`,
              pl: `calc(16px - 5px)`,
            },
            "&.Mui-selected:hover": {
              backgroundColor: alpha(theme.palette.primary.dark, 0.25),
              borderLeft: `5px solid ${theme.palette.primary.dark}`,
            },
            "&:hover": {
              backgroundColor: alpha(theme.palette.primary.dark, 0.15),
            },
          }}
        >
          <Box className="UserListItem">
            <Box className={styles.userLogo}>
              <UserLogo name={item.name} disabled={item.disabled} />
            </Box>
            <Box>
              <label className={styles.heading}>{item?.name}</label>
            </Box>
          </Box>
        </ListItemButton>
      </Disabled>
    ) : (
      <></>
    );
  };

  const handleListItemClick = async (event, item) => {
    let flagedItem = { ...item, newRecord: false };
    setSelectedUser(flagedItem);
  };

  const dispatch = useDispatch();

  const [canEdit, setCanEdit] = useState(false);

  const currentUser = useSelector(selectCurrentUser);

  const filterString = useSelector(selectUserFilter);
  const [sortedItems, setsortedItems] = useState(null);

  const handleDeviceDialogOpen = () => {
    dispatch(setDeviceDialogOpen(true));
  };

  const handleDeleteConfirmationDialogOpen = () => {
    dispatch(setUsersConfirmationDialogOpen(true));
  };

  function Disabled({ disabled, children }) {
    if (disabled) {
      return (
        <div style={{ opacity: 0.5, pointerEvents: "none" }} disabled>
          {children}
        </div>
      );
    }

    return <React.Fragment>{children}</React.Fragment>;
  }

  useEffect(() => {
    setsortedItems(
      users.filter((d) =>
        d.name.toLowerCase().includes(filterString.toLowerCase())
      )
    );
  }, [users, filterString]);

  useEffect(() => {
    if (currentUser?.data.readonly || currentUser?.data.deviceReadonly) {
      setCanEdit(false);
    } else {
      setCanEdit(true);
    }
  }, [currentUser]);

  return (
    <Box
      className="UserListContainer"
      sx={{ backgroundColor: "primary.verylight" }}
    >
      <Box
        sx={{ height: `${appBarHeight}px`, backgroundColor: "primary.dark" }}
      >
        <UserSearch hasChanges={hasChanges} canEdit={canEdit} />
      </Box>

      <Box
        style={{
          opacity: 1,
          backgroundColor: "white",
        }}
        sx={{
          width: drawerWidth,
        }}
      >
        <Box className="UserList" sx={{ height: `${controlHeight}px` }}>
          <AutoSizer>
            {({ height, width }) => (
              // <ListComponent
              //   devices={sortedItems}
              //   controlHeight={height}
              //   drawerWidth={width}
              //   dispatch={dispatch}
              //   onClose={onClose}
              // />

              <>
                {sortedItems ? (
                  <div>
                    <List
                      height={height}
                      width={width}
                      itemSize={50}
                      itemCount={sortedItems.length}
                      itemData={{
                        sortedItems,
                        setSelectedUser,
                        selectedUser,
                      }}
                    >
                      {ItemRenderer}
                    </List>
                  </div>
                ) : null}
              </>
            )}
          </AutoSizer>
        </Box>
      </Box>

      <Box
        sx={{
          height: `${appBarHeight}px`,
          backgroundColor: "primary.dark",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={9}></Grid>
          <Grid item xs={3}>
            <Tooltip title="Remove User">
              <span>
                <IconButton
                  onClick={handleDeleteConfirmationDialogOpen}
                  disabled={
                    (!canEdit || hasChanges) && Object.keys(user).length !== 0
                  }
                  sx={{
                    ":hover": {
                      bgcolor: "primary.light", // theme.palette.primary.main
                      color: "primary.dark",
                    },
                  }}
                  aria-label="open drawer"
                  edge="end"
                >
                  <PersonAddDisabledTwoToneIcon
                    sx={{ fontSize: 40, pr: 0, mr: 0 }}
                    style={{
                      opacity:
                        canEdit && !hasChanges && Object.keys(user).length !== 0
                          ? 1
                          : 0.5,
                      color: "#fff",
                    }}
                  />
                </IconButton>
              </span>
            </Tooltip>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const areEqual = (prevProps, nextProps) => {
  const { children: prevChildren, ...restOfPrevProps } = prevProps;
  const { children: nextChildren, ...restOfNextProps } = nextProps;

  if (prevProps !== nextProps) {
    return false;
  }

  return true; // donot re-render
};

export default memo(UserList, areEqual);
