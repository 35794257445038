import { useMemo, useState } from "react";
import { Menu, Item, Separator, Submenu, ItemParams } from "react-contexify";
import { useEffectAsync } from "../../common/reactHelper";
import { useSelector, useDispatch } from "react-redux";
import "react-contexify/dist/ReactContexify.css";
import "./cstyle.css";

import {
  getDeviceCommandsAsync,
  selectDeviceCommandsQueryState,
  selectDeviceCommands,
} from "../../deviceList/commandsListSlice";

export default function ContextMenu(props) {
  const { menuId, handleItemClick, selectedId } = props;

  const dispatch = useDispatch();

  const commands = useSelector(selectDeviceCommands);
  const commandsQueryState = useSelector(selectDeviceCommandsQueryState);

  const [ready, setReady] = useState(false);
  const [items, setItems] = useState([]);

  useEffectAsync(async () => {
    if (selectedId != -1) {
      setReady(false);
      const response = await dispatch(
        getDeviceCommandsAsync(selectedId)
      ).unwrap();

      const mi = [];

      response.map((item) =>
        mi.push({
          key: mi.length,
          label: item.description,
          hasSubmenu: false,
          fullitem: item,
          deviceId: selectedId,
        })
      );
      setItems(mi);
    }
  }, [selectedId]);

  const children = useMemo(() => {
    const elements = [];

    for (let index = 0; index < items.length; index++) {
      const item = items[index];

      elements.push(
        <Item
          data={item}
          key={elements.length}
          onClick={handleItemClick}
          disabled={item.disabled}
        >
          {item.label}
        </Item>
      );
      // if (index !== items.length - 1)
      //   elements.push(<Separator key={elements.length} />);
      if (item.hasSubmenu)
        elements.push(
          <Submenu label={item.label}>
            {item.subMenuItems?.map((subMenuItem) => (
              <Item
                key={elements.length}
                onClick={subMenuItem.handleItemClick}
                disabled={subMenuItem.disabled}
              >
                {subMenuItem.label}
              </Item>
            ))}
          </Submenu>
        );
    }
    setReady(true);

    return elements;
  }, [items]);

  return (
    <>
      {ready ? (
        <Menu id={menuId} animation={false}>
          {children}
        </Menu>
      ) : (
        <Menu id={menuId} animation={false} className="hidden">
          <Item key="gettingKey" disabled={true}>
            <h1>Getting This is invicible for now</h1>
          </Item>
        </Menu>
      )}
    </>
  );
}
