import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getTrips, getStops } from "./positionsApi";

const initialState = {
  tripsStatus: "idle",
  stopsStatus: "idle",
  deviceTrips: {},
  deviceStops: {},
};

export const getTripsAsync = createAsyncThunk(
  "DeviceReportsList/getTrips",
  async (params) => {
    const deviceId = params[0];
    const from = params[1];
    const to = params[2];

    const response = await getTrips(deviceId, from, to);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const getStopsAsync = createAsyncThunk(
  "DeviceReportsList/getStops",
  async (params) => {
    const deviceId = params[0];
    const from = params[1];
    const to = params[2];

    const response = await getStops(deviceId, from, to);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const deviceReportsSlice = createSlice({
  name: "deviceTripsList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearTrips(state, action) {
      state.deviceTrips = {};
    },
    clearStops(state, action) {
      state.deviceStops = {};
    },
  },

  extraReducers: (builder) => {
    builder
      //Get Trips
      .addCase(getTripsAsync.pending, (state) => {
        state.tripsStatus = "loading";
      })
      .addCase(getTripsAsync.fulfilled, (state, action) => {
        state.tripsStatus = "idle";
        state.deviceTrips = action.payload;
      })
      .addCase(getStopsAsync.pending, (state) => {
        state.stopsStatus = "loading";
      })
      .addCase(getStopsAsync.fulfilled, (state, action) => {
        state.stopsStatus = "idle";
        state.deviceStops = action.payload;
      });
  },
});

export const { clearTrips } = deviceReportsSlice.actions;
export const { clearStops } = deviceReportsSlice.actions;

export const selectDeviceTrips = (state) =>
  Object.values(state.deviceReportsList.deviceTrips);
export const selectDeviceStops = (state) =>
  Object.values(state.deviceReportsList.deviceStops);

export const selectDeviceTripsQueryState = (state) =>
  state.deviceReportsList.tripsStatus;
export const selectDeviceStopsQueryState = (state) =>
  state.deviceReportsList.stopsStatus;

export default deviceReportsSlice.reducer;
