import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectFilter,
  setFilter,
  setSelectedId,
  clearAllSelections,
} from "./deviceListSlice";
import Tooltip from "@mui/material/Tooltip";
import { styled, alpha } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import AddLocationTwoToneIcon from "@mui/icons-material/AddLocationTwoTone";

import InputBase from "@mui/material/InputBase";

import SearchIcon from "@mui/icons-material/Search";
import IconWrapper from "../common/IconWrapper";

import { setDeviceDialogOpen } from "../dialogs/dialogStateSlice";
import { selectCurrentUser } from "../login/loginSlice";
import { selectListCount } from "../deviceList/deviceListSlice";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.35),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.45),
    borderRadius: theme.shape.borderRadius,
  },
  "& .MuiInputBase-input:focus": {
    backgroundColor: alpha(theme.palette.common.white, 0.55),
    borderRadius: theme.shape.borderRadius,
  },
  marginLeft: 0,
  width: "auto",
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(0.2em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
}));

const DeviceSearch = () => {
  const dispatch = useDispatch();

  const [canAdd, setcanAdd] = useState(true);

  const currentUser = useSelector(selectCurrentUser);

  const listCount = useSelector(selectListCount);

  useEffect(() => {
    let canAdd = false;

    if (
      (!currentUser.data.deviceReadonly &&
        !currentUser.data.readonly &&
        !currentUser.data.administrator !== true) ||
      currentUser.data.deviceLimit === 0
    ) {
      if (
        currentUser.data.deviceLimit > listCount ||
        currentUser.data.deviceLimit === -1
      ) {
        canAdd = true;
      }
    }

    setcanAdd(canAdd);
  }, [currentUser, listCount]);

  const filterString = useSelector(selectFilter);

  const onSerachChange = (e) => {
    dispatch(setFilter(e.target.value));
  };

  const handleAddTrackerClick = (event) => {
    dispatch(setSelectedId(-1));
    dispatch(clearAllSelections());
    dispatch(setDeviceDialogOpen(true));
  };

  return (
    <>
      <Toolbar
        variant="regular"
        disableGutters={canAdd}
        sx={{ pl: 1, pr: 0, mr: 0 }}
      >
        <Tooltip title="Search name or group.">
          <Search>
            <IconWrapper></IconWrapper>

            <StyledInputBase
              placeholder="Search Name or Group…"
              inputProps={{ "aria-label": "search" }}
              id="search"
              value={filterString}
              onChange={onSerachChange}
            />
          </Search>
        </Tooltip>
        {canAdd ? (
          <Tooltip title="Add Tracker">
            <IconButton
              aria-label="Add Tracker"
              variant="text"
              sx={{
                padding: "2px",
                marginLeft: "2px",
                ":hover": {
                  bgcolor: "primary.light", // theme.palette.primary.main
                  color: "primary.dark",
                },
              }}
              onClick={handleAddTrackerClick}
            >
              <AddLocationTwoToneIcon
                sx={{ fontSize: 40, pr: 0, mr: 0 }}
                style={{ color: "#fff" }}
              />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
      </Toolbar>
    </>
  );
};

export default DeviceSearch;
