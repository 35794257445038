import React from "react";
import _ from "lodash";

import { useTheme } from "@mui/material/styles";
import { useMap, Source, Layer } from "react-map-gl";

import circle from "@turf/circle";

import { getBounds } from "../common/getBounds";

const MapAdornments = (props) => {
  const theme = useTheme();
  const { mainMap } = useMap();
  const { devices, positions, hasMapMoved } = props;

  //Return empty layer to allow query of layer before device chosen.  Hack!

  if (!devices?.length > 0)
    return (
      <Source id="markers" type="geojson" data={null}>
        <Layer id="markerLayer" type="symbol" />
      </Source>
    );

  if (mainMap && devices?.length > 0) {
    var zoom = mainMap.getZoom();

    if (zoom <= 3) {
      zoom = 10;
    }

    const options = {
      padding: {
        top: 100,
        bottom: 100,
        left: 100,
        right: 100,
      },
      linear: true,
      maxZoom: zoom,
    };

    const bounds = getBounds(positions);

    if (!hasMapMoved && bounds) {
      mainMap.fitBounds(getBounds(positions), options);
    }

    //////////////////////////////////////////////////////////////////////////////////////////
    //GeoJson Holders
    //////////////////////////////////////////////////////////////////////////////////////////

    const markerGeojson = {
      type: "FeatureCollection",
      features: [],
    };

    const accuracyGeojson = {
      type: "FeatureCollection",
      features: [],
    };

    const textGeojson = {
      type: "FeatureCollection",
      features: [],
    };

    //////////////////////////////////////////////////////////////////////////////////////////
    //Loop to add features
    //////////////////////////////////////////////////////////////////////////////////////////

    devices.forEach((device, index, arr) => {
      if (positions[index]) {
        const marker = {
          type: "Feature",
          properties: {
            id: device["id"],
            name: device["name"],
            lat: positions[index]["latitude"],
            lng: positions[index]["longitude"],
            iconSize: [72, 72],
            rotation: positions[index]["course"],
          },
          geometry: {
            type: "Point",
            coordinates: [
              positions[index]["longitude"],
              positions[index]["latitude"],
            ],
          },
        };

        const accuracy = circle(
          [positions[index]["longitude"], positions[index]["latitude"]],
          positions[index]["accuracy"] * 0.001
        );

        const text = {
          type: "Feature",
          properties: {
            id: `text${device["id"]}`,
            description: device["name"],
          },
          geometry: {
            type: "Point",
            coordinates: [
              positions[index]["longitude"],
              positions[index]["latitude"],
            ],
          },
        };

        markerGeojson.features.push(marker);
        accuracyGeojson.features.push(accuracy);
        textGeojson.features.push(text);
      }
    });

    //////////////////////////////////////////////////////////////////////////////////////////
    //LAYER STYLES
    //////////////////////////////////////////////////////////////////////////////////////////
    const markerLayerStyle = {
      id: "markerLayer",
      type: "symbol",
      scale: 1,
      layout: {
        "icon-image": "devicepointer",
        "icon-size": 1,
        "icon-anchor": "top",
        "icon-rotation-alignment": "map",
        "icon-rotate": ["get", "rotation"],
        "icon-allow-overlap": true,
      },
    };

    const accuracyLayerStyle = {
      id: `accuracyLayer`,
      type: "fill",
      paint: {
        "fill-color": `${theme.palette.primary.dark}`,
        "fill-outline-color": "#000000",
        "fill-opacity": 0.25,
      },
    };

    const textLayerStyle = {
      id: `layerText`,
      type: "symbol",
      paint: {
        "text-color": `${theme.typography.allVariants.color}`, //Color of your choice
        "text-halo-blur": 5,
        "text-halo-color": `${theme.palette.background.paper}`,
        "text-halo-width": 2,
        "text-opacity": 1,
      },
      layout: {
        "text-field": ["get", "description"], //This will get "description" property from your geojson
        "text-font": ["Open Sans Semibold"],
        "text-size": 15,
        "text-rotation-alignment": "auto",
        "text-offset": [0, 1],
        "text-anchor": "top",
        "text-allow-overlap": true,
      },
    };

    return (
      <>
        <Source id="markers" type="geojson" data={markerGeojson}>
          <Layer {...markerLayerStyle} />
        </Source>
        <Source id="accuracyCircles" type="geojson" data={accuracyGeojson}>
          <Layer {...accuracyLayerStyle} />
        </Source>
        <Source id="text" type="geojson" data={textGeojson}>
          <Layer {...textLayerStyle} />
        </Source>
      </>
    );
  } else return null;
};

function isPropIsEqual(prevProps, nextProps) {
  if (
    _.isEqual(prevProps.hasMapMoved, nextProps.hasMapMoved) &&
    _.isEqual(prevProps.devices, nextProps.devices) &&
    _.isEqual(prevProps.positions, nextProps.positions) &&
    _.isEqual(prevProps.deviceTrace, nextProps.deviceTrace)
  ) {
    return true;
  }
  return false;
}

export default React.memo(MapAdornments, isPropIsEqual);
