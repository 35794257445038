import moment from "moment";

export async function useSumerizePower(positions) {
  const summarizedData = {};

  summarizedData.batteryLevel = [];
  summarizedData.powerPos = [];
  summarizedData.ignitionPos = [];
  summarizedData.allData = [];

  let lastTs = moment("2007-03-01T13:00:00Z");

  positions.forEach((position, index, array) => {
    const fixTime = moment(position.fixTime);

    const ts = fixTime.valueOf();

    const id = position.id;
    const protocol = position.protocol;

    const latitude = position.latitude;
    const longitude = position.longitude;
    const altitude = position.altitude;
    let speed = position.speed;
    const course = position.course;
    const address = position.address;
    const accuracy = position.accuracy;
    const network = position.network;
    const attributes = position.attributes;
    let valid = position.valid;

    let power = attributes.power;
    let battery = attributes.battery;
    const ignition = attributes.ignition;

    const satellites = attributes.sat;

    let gpsStatus = attributes.gpsStatus;

    if (gpsStatus == undefined) {
      gpsStatus = attributes.io69;
    }

    // if (gpsStatus == null) {
    //   gpsStatus = attributes["GNSS Status"];
    // }

    let ignitionNumeric = 0;
    let ignitionPosition = "OFF";

    if (ignition === true) {
      ignitionNumeric = 1;
      ignitionPosition = "ON";
    }

    let isValid = "YES";
    if (valid === true) {
      isValid = "YES";
    } else {
      isValid = "NO";
    }

    let gpsTextStatus = "";
    // 0 - GPS OFF
    // 1 – GPS ON with fix
    // 2 - GPS ON without fix
    // 3 - GPS sleep

    switch (gpsStatus) {
      case 0:
        gpsTextStatus = "GPS OFF";
        break;
      case 1:
        gpsTextStatus = "GPS ON with fix";
        break;
      case 2:
        gpsTextStatus = "GPS ON without fix";
        break;
      case 3:
        gpsTextStatus = "GPS sleep";
        break;
      default:
        gpsTextStatus = "NO INFO";
    }

    if (speed > 0) {
      speed = (speed * 1.15078).toFixed(2) + " mph";
    }

    if (ts) {
      summarizedData.batteryLevel.push({ x: ts, y: battery });
      summarizedData.powerPos.push({ x: ts, y: power });
      summarizedData.ignitionPos.push({ x: ts, y: ignitionNumeric });

      const data = {};

      data.id = id;
      data.fixTime = ts;
      data.satellites = satellites;
      data.gpsStatus = gpsTextStatus;
      data.valid = isValid;

      data.protocol = protocol;
      data.accuracy = accuracy;
      data.latitude = latitude;
      data.longitude = longitude;
      data.altitude = altitude;
      data.speed = speed;
      data.course = course;
      data.ignitionPos = ignitionPosition;
      data.powerPos = power;
      data.battery = battery;
      data.protocol = protocol;
      data.address = address;
      data.network = network;
      data.attributes = attributes;

      summarizedData.allData.push(data);
    }
  });

  return summarizedData;
}
