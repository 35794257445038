import React, { PureComponent } from "react";
import CanvasJSReact from "../../assets/canvasjs.react";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import BatteryLevel from "./BatterLevel";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

export default class BatteryCharts extends PureComponent {
  constructor(props) {
    super(props);
    this.charts = [];
  }

  render() {
    var sumData = this.props.sumData;

    var powerData = [
      {
        type: "line",
        color: this.props.mainColour,
        xValueType: "dateTime",
        dataPoints: sumData.batteryLevel,
      },
    ];

    var powerOptions = {
      data: powerData,
      zoomEnabled: true,
      exportEnabled: false,
      animationEnabled: false,

      axisY: {
        labelFontSize: 14,
        labelFontFamily: "Arial",
        minimum: 0,
        maximum: 7.5,
        interval: 0.25,
        labelAutoFit: true,
      },
      axisX: {
        labelFontSize: 14,
        labelFontFamily: "Arial",
        interval: 1,
        labelAutoFit: true,
      },
    };

    var ev = {};
    ev.trigger = "reset";

    const LineUpMarging = () => {
      var charts = this.charts;
      var doCalc = true;

      for (var i = 0; i < charts.length; i++) {
        if (!charts[i]?.axisY[0]) {
          doCalc = false;
        }
      }
      if (doCalc) {
        var axisYBoundMax = 0;
        for (var i = 0; i < charts.length; i++) {
          axisYBoundMax = Math.max(axisYBoundMax, charts[i].axisY[0].bounds.x2);
        }

        for (var i = 0; i < charts.length; i++) {
          charts[i].axisY[0].set(
            "margin",
            axisYBoundMax -
              (charts[i].axisY[0].bounds.x2 - charts[i].axisY[0].bounds.x1)
          );
        }
      }
    };

    const containerProps1 = {
      width: "100%",
      height: "100%",
    };

    return (
      <div className="chartContainer">
        <CanvasJSChart
          containerProps={containerProps1}
          options={powerOptions}
          // onRef={(ref) => {
          //   this.charts.push(ref);
          //   LineUpMarging();
          // }}
        />
      </div>
    );
  }
}
