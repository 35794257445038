import DeviceAttributes from "./DeviceAttributes";

const DeviceDetails = (props) => {
  return (
    <div className="chartContainer">
      <DeviceAttributes selectedId={props.selectedId} />
    </div>
  );
};
export default DeviceDetails;
