import UtilizCharts from "./UtilizCharts";
import { useTheme } from "@mui/material/styles";

const Utilization = (props) => {
  const theme = useTheme();

  return (
    <div className="chartContainer">
      {props.SummarizedPositions?.powerPos?.length > 0 ? (
        <UtilizCharts
          sumData={props.SummarizedPositions}
          mainColour={theme.palette.primary.dark}
        />
      ) : (
        <p>No Utilization Data, Get Data.</p>
      )}
    </div>
  );
};
export default Utilization;
