import React, { useEffect, Fragment } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginWithTokenAsync } from "../login/loginSlice";

const PersistLogin = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const authToken = localStorage.getItem("authtoken");

    if (authToken) {
      dispatch(loginWithTokenAsync(authToken));
    }
  }, []);

  return <Fragment>{props.children}</Fragment>;
};

export default PersistLogin;
