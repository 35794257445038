import BatteryCharts from "./BatteryCharts";
import { useTheme } from "@mui/material/styles";

const BatteryLevel = (props) => {
  const theme = useTheme();

  return (
    <div className="chartContainer">
      {props.SummarizedPositions?.powerPos?.length > 0 ? (
        <BatteryCharts
          sumData={props.SummarizedPositions}
          mainColour={theme.palette.primary.dark}
        />
      ) : (
        <p>No Battery Data, Get Data.</p>
      )}
    </div>
  );
};
export default BatteryLevel;
