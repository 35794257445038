import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { geoCode } from "../geocoding/geoCodeAPI";
import { decode } from "html-entities";

const initialState = {
  status: "idle",
  portalName: null,
};

export const geoCodeAsync = createAsyncThunk(
  "geoCoder/geoCode",
  async (coords, { rejectWithValue }) => {
    try {
      const response = await geoCode(coords);
      // The value we return becomes the `fulfilled` action payload
      const responseData = {};
      responseData.status = response.status;
      responseData.statusText = response.statusText;
      responseData.data = response.data;

      return responseData;
    } catch (err) {
      const errResponse = {};
      errResponse.status = err.response.status;
      errResponse.statusText = err.response.statusText;
      errResponse.message = err.response.message;

      return rejectWithValue(errResponse);
    }
  }
);

export const geoCoderSlice = createSlice({
  name: "geoCoder",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(geoCodeAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(geoCodeAsync.fulfilled, (state, action) => {
        state.portalName = "HELLO";
        state.status = "success";
      })
      .addCase(geoCodeAsync.rejected, (state, action) => {
        state.status = "rejected";
      });
  },
});

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default geoCoderSlice.reducer;
