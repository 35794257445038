import http from "../../services/httpService";
import config from "../../config.json";

export async function addDevice(device) {
  const response = await http.post(config.apiUrl + "/devices", device, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      "Content-Type": "application/json",
    },
  });
  return response;
}
export async function updateDevice(device) {
  const response = await http.put(
    config.apiUrl + "/devices/" + device.id,
    device,
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    }
  );
  return response;
}

export async function deleteDevice(device) {
  const response = await http.delete(config.apiUrl + "/devices/" + device.id);

  return response;
}
