export function ConvertDegrees(degrees) {
  const sectors = [
    "North",
    "North North East",
    "North East",
    "East North East",
    "East",
    "East South East",
    "South East",
    "South South East",
    "South",
    "South South West",
    "South West",
    "West South West",
    "West",
    "West North West",
    "North West",
    "North North West",
    "North",
  ];
  return sectors[Math.round(degrees / 22.5, 0) + 1];
}

export function Convert_KN_MPH(kn) {
  const speed = (kn * 1.15078).toFixed(2);

  return Math.round(speed * 100) / 100 + " mph";
}
