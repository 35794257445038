import http from "../../services/httpService";
import config from "../../config.json";

export async function addUser(User) {
  const response = await http.post(config.apiUrl + "/users", User, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      "Content-Type": "application/json",
    },
  });
  return response;
}
export async function updateUser(User) {
  const response = await http.put(config.apiUrl + "/users/" + User.id, User, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function deleteUser(User) {
  const response = await http.delete(config.apiUrl + "/users/" + User.id);

  return response;
}
