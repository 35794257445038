import * as React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { AutoSizer } from "@enykeev/react-virtualized";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import { red } from "@mui/material/colors";

import moment from "moment";

import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import { useDispatch } from "react-redux";
import { ExportExcel } from "../common/excelExport";

import {
  setManualMove,
  setDetailsDrawOpen,
  selectDetailsDrawOpen,
} from "../map/mapStateSlice";

import { showPointOnMap } from "../../features/deviceList/devicePositionsSlice";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function parseMillisecondsIntoReadableTime(milliseconds) {
  //Get hours from milliseconds
  var hours = milliseconds / (1000 * 60 * 60);
  var absoluteHours = Math.floor(hours);
  var h = absoluteHours > 9 ? absoluteHours : "0" + absoluteHours;

  //Get remainder from hours and convert to minutes
  var minutes = (hours - absoluteHours) * 60;
  var absoluteMinutes = Math.floor(minutes);
  var m = absoluteMinutes > 9 ? absoluteMinutes : "0" + absoluteMinutes;

  //Get remainder from minutes and convert to seconds
  var seconds = (minutes - absoluteMinutes) * 60;
  var absoluteSeconds = Math.floor(seconds);
  var s = absoluteSeconds > 9 ? absoluteSeconds : "0" + absoluteSeconds;

  return h + "h: " + m + ":m " + s + "s";
}

function createData(
  index,
  startTime,
  endTime,
  address,
  duration,
  latitude,
  longitude
) {
  // startLat = startLat.toFixed(4);
  // startLon = startLon.toFixed(4);

  // endLat = endLat.toFixed(4);
  // endLon = endLon.toFixed(4);

  const sDate = new Date(startTime);
  startTime = moment(sDate).format("DD/MM/yyyy HH:mm a");
  const eDate = new Date(endTime);
  endTime = moment(eDate).format("DD/MM/yyyy HH:mm a");

  duration = parseMillisecondsIntoReadableTime(duration);

  address = address
    .replace("Sant Nirankari Satsang Bhawan,", "TPH Depot Birmingham,")
    .replace("Tank Farm Road,", "TPH Depot South Wales, Tank Farm Road,")
    .replace("Null Island", "No Fix")
    .replace("Ashbrook Plant Hire,", "TPH Depot Bangor,")
    .replace("&#39;", "'")
    .replace("none, island,", "No Fix")
    .replace("Llwybr-Hir,", "TPH Depot Holwell, Llwybr-Hir,")
    .replace("unnamed road,", "Unnamed Road,")
    .replace("Ditton Road,", "TPH Depot Widnes, Ditton Road,");

  return {
    index,
    address,
    startTime,
    endTime,
    duration,
    latitude,
    longitude,
    sDate,
    eDate,
  };
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const columns = [
  {
    id: "startTime",
    label: "Start Time",
    minWidth: 110,
    align: "left",
  },
  { id: "endTime", label: "End Time", minWidth: 70, align: "left" },
  {
    id: "address",
    label: "Address",
    minWidth: 200,
    align: "left",
    format: (value) =>
      value
        .replace("Sant Nirankari Satsang Bhawan,", "")
        .replace("College of Nursing (Disused),", ""),
  },

  { id: "duration", label: "Duration", minWidth: 70, align: "right" },

  // {
  //   id: "button",
  //   label: (
  //     <Button
  //       variant="contained"
  //       color="success"
  //       sx={{
  //         marginRight: "10px",
  //         width: "100%",
  //         ml: 3,
  //         whiteSpace: "nowrap",
  //         minWidth: "max-content",
  //       }}
  //     >
  //       Export
  //     </Button>
  //   ),
  //   minWidth: 70,
  //   maxWidth: 70,
  //   align: "right",
  // },
];

export default function StopsTable(props) {
  const dispatch = useDispatch();

  const rows = [];
  var { stopsData, id } = props;

  stopsData.stops.forEach((el) => {
    rows.push(
      createData(
        stopsData.stops.indexOf(el),
        el.startTime,
        el.endTime,
        el.address,
        el.duration,
        el.latitude,
        el.longitude
      )
    );
  });

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const exportExcel = async () => {
    ExportExcel(rows, "Stop_Data", "Stops");
  };

  function exportCurrentData() {
    exportExcel();
  }

  function viewOnMap(id, sDate, eDate, address, duration, latitude, longitude) {
    const mapProfile = {};

    mapProfile.id = id;
    mapProfile.startTime = sDate;
    mapProfile.endTime = eDate;
    mapProfile.address = address;
    mapProfile.duration = duration;
    mapProfile.latitude = latitude;
    mapProfile.longitude = longitude;
    mapProfile.type = "stop";

    dispatch(setManualMove(true));

    dispatch(showPointOnMap(mapProfile));

    dispatch(setDetailsDrawOpen(false));
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <Paper sx={{ width: { width }, height: { height } }}>
          <TableContainer sx={{ height: "90%" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow key={"header"}>
                  {columns.map((column) => (
                    <StyledTableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      {column.label}
                    </StyledTableCell>
                  ))}
                  <StyledTableCell key="ExportButton" style={{ width: 70 }}>
                    <Button
                      onClick={() => exportCurrentData()}
                      variant="contained"
                      color="success"
                      size="small"
                      sx={{
                        width: 100,
                        height: 23,
                        ml: 3,
                        color: "white",
                        whiteSpace: "nowrap",
                        minWidth: "max-content",
                      }}
                    >
                      Export
                    </Button>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row) => {
                    return (
                      <TableRow
                        key={row.index}
                        hover
                        role="checkbox"
                        tabIndex={-1}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.format && typeof value === "number"
                                ? column.format(value)
                                : value}
                            </TableCell>
                          );
                        })}
                        <TableCell key="vieOnMap" style={{ width: 70 }}>
                          <Button
                            onClick={() =>
                              viewOnMap(
                                id,
                                row["sDate"],
                                row["eDate"],
                                row["address"],
                                row["duration"],
                                row["latitude"],
                                row["longitude"]
                              )
                            }
                            variant="contained"
                            sx={{
                              ml: 3,
                              color: "white",
                              whiteSpace: "nowrap",
                              minWidth: "max-content",
                            }}
                          >
                            View Map
                          </Button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component={"div"}
            rowsPerPageOptions={[5, 10, 25]}
            colSpan={3}
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            SelectProps={{
              inputProps: {
                "aria-label": "rows per page",
              },
              native: true,
            }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      )}
    </AutoSizer>
  );
}
