import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  getDevicesAsync,
  clearAllDevices,
  clearAllGroups,
  clearAllSelectedDevices,
  getGroupsAsync,
  updateItems,
  updatePositions,
  selectDevices,
} from "../features/deviceList/deviceListSlice";

import { selectCurrentUser } from "../features/login/loginSlice";

import { useEffectAsync } from "../features/common/reactHelper";

import config from "../config.json";
import { toast } from "react-toastify";

const toastWithLink = (message) => {
  let displayResult = "";
  let url = "";

  try {
    if (message && message.includes("http")) {
      const startProto = message.indexOf("Url:");
      const startBr = message.length;

      displayResult = message.substring(0, startProto);
      url = message.substring(startProto + 5, startBr);
    } else {
      displayResult = message;
    }
  } catch {
    displayResult = message;
  }

  return (
    <div>
      {displayResult !== "" && <p>{displayResult}</p>}
      {url !== "" && (
        <a href={url} target="_blank">
          {url}
        </a>
      )}
    </div>
  );
};

const SocketController = () => {
  const dispatch = useDispatch();
  const currentUser = useSelector(selectCurrentUser);

  const socketRef = useRef();

  const connectSocket = () => {
    const socket = new WebSocket(config.socketUrl + "/socket");
    socketRef.current = socket;

    socket.onerror = (error) => {
      setTimeout(() => connectSocket(), 60 * 1000);
    };
    socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.devices) {
        //dispatch(updateItems(data.devices));
      }
      if (data.positions) {
        dispatch(updatePositions(data.positions));
      }
      if (data.events) {
        data.events.forEach((event, index) => {
          if (event?.attributes.result) {
            toast.info(toastWithLink(event?.attributes?.result));
          }
          if (event?.attributes.message) {
            toast.info(toastWithLink(event?.attributes?.message));
          }
        });
      }
    };
  };

  // const devices = useSelector(selectDevices);

  // useEffect(() => {
  //   alert(devices?.length);
  // }, [devices]);

  useEffectAsync(async () => {
    if (currentUser) {
      try {
        dispatch(clearAllDevices());
        dispatch(clearAllSelectedDevices());
        dispatch(clearAllGroups());

        dispatch(getDevicesAsync(currentUser.data.id));

        dispatch(getGroupsAsync(currentUser.data.id));

        connectSocket();

        return () => {
          const socket = socketRef.current;
          if (socket) {
            socket.close();
          }
        };
      } catch (ex) {
        console.log("caught error", ex);
      }
    }

    return null;
  }, [currentUser]);

  return null;
};

export default SocketController;
