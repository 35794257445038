import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { styled } from "@mui/material/styles";

import { useEffectAsync } from "../common/reactHelper";
import styles from "./Dialog.module.css";
import Button from "@mui/material/Button";
//import Dialog from "@mui/material/Dialog";
import Modal from "@mui/material/Modal";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import BottomDialogAppBar from "./BottomDialogAppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";
import useAppBarHeight from "../../features/common/customHooks/useAppBarHeight";
import FormControl from "@mui/material/FormControl";

import { setUsersDialogOpen } from "../dialogs/dialogStateSlice";

import UserList from "../userList/UserList";
import UserSearch from "../userList/userSearch";

import { toast } from "react-toastify";
import _ from "lodash";

import { removeListDevice } from "../userList/userListSlice";

import {
  selectUsers,
  selectSelectedUser,
  setSelectedUser,
  getUsersAsync,
} from "../userList/userListSlice";

import UserScreen from "../userList/UserScreen";
import UserConnections from "../userList/UserConnections";
import { object } from "yup";
import { Label } from "@mui/icons-material";

const drawerWidth = 280;

function Disabled({ disabled, children }) {
  if (disabled) {
    return (
      <>
        <div style={{ opacity: 0.5, pointerEvents: "none" }} disabled>
          {children}
        </div>
      </>
    );
  }

  return <React.Fragment>{children}</React.Fragment>;
}

const StyledDialog = styled(Modal)(({ theme }) => ({
  root: {
    "&+.MuiDialogContent-root": {
      paddingTop: "10px !important",
    },
  },
  "& MuiDialog-paper": {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  "& .MuiTypography-h6": {
    paddingRight: "0px",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        height: 1,
        overflow: "auto",
        "& .MuiBox-root": {
          height: "100%",
          // p: 0,
          // m: 0,
        },
      }}
      className="tabpanel"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children} </Box>}
    </Box>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const UsersDialog = (props) => {
  const { open, height, groups } = props;

  const appBarHeight = useAppBarHeight();
  const { breakpoints } = useTheme();
  const isMobile = useMediaQuery(breakpoints.down("md"));

  const handleClose = () => {
    setHasChanges(false);
    dispatch(setSelectedUser({}));
    dispatch(setUsersDialogOpen(false));
  };

  useEffectAsync(async () => {
    dispatch(getUsersAsync());
  }, []);

  // useEffect(() => {
  //   setMobileOpen(true);
  // }, []);

  const users = useSelector(selectUsers);

  const selectedUser = useSelector(selectSelectedUser);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [hasChanges, setHasChanges] = React.useState(false);

  //const [selectedUser, setSelectedUser] = React.useState(null);

  const setSelectedUserProxy = (item) => {
    dispatch(setSelectedUser(item));
    setMobileOpen(false);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleLocalClose = async () => {
    handleClose();
  };

  const [index, setIndex] = React.useState(0);

  const handleChange = (event, newValue) => {
    setIndex(newValue);
  };

  const dispatch = useDispatch();

  const theme = useTheme();

  return (
    <div>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="Add Device"
        id="drawer-container"
        position="relative"
      >
        <Box sx={{ display: "flex", height: "100%" }}>
          <CssBaseline />
          <AppBar
            position="fixed"
            sx={{
              bgcolor: "primary.dark",
              width: { md: `calc(100% - ${drawerWidth}px)` },
              ml: { md: `${drawerWidth}px` },
            }}
          >
            <Toolbar sx={{ width: "100%" }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { md: "none" } }}
              >
                <MenuIcon sx={{ fontSize: 42 }} />
              </IconButton>
              <Typography variant="h4" noWrap component="div">
                System Users
              </Typography>
            </Toolbar>
          </AppBar>
          <div component="nav" aria-label="mailbox folders">
            {isMobile ? (
              //MOBILE DRAW
              <Drawer
                // container={container}
                variant="temporary"
                open={mobileOpen}
                onClose={handleDrawerToggle}
                PaperProps={{
                  style: { position: "absolute", width: { drawerWidth } },
                }}
                ModalProps={{
                  keepMounted: true,
                  container: document.getElementById("drawer-container"),
                  style: { position: "absolute" },
                }}
                sx={{
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                  },
                }}
              >
                <UserList
                  appBarHeight={appBarHeight}
                  controlHeight={height - appBarHeight * 2}
                  drawerWidth={drawerWidth}
                  onClose={handleDrawerToggle}
                  users={users}
                  setSelectedUser={setSelectedUserProxy}
                  selectedUser={selectedUser}
                  hasChanges={hasChanges}
                />
              </Drawer>
            ) : (
              //DESKTOP DRAW

              <Drawer
                variant="permanent"
                sx={{
                  width: drawerWidth,
                  "& .MuiDrawer-paper": {
                    boxSizing: "border-box",
                    width: drawerWidth,
                    borderRight: 0,
                  },
                }}
                open
              >
                <UserList
                  appBarHeight={appBarHeight}
                  controlHeight={height - appBarHeight * 2}
                  drawerWidth={drawerWidth}
                  users={users}
                  setSelectedUser={setSelectedUserProxy}
                  selectedUser={selectedUser}
                  hasChanges={hasChanges}
                />
              </Drawer>
            )}
          </div>

          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 0,
              height: `calc(100% - ${appBarHeight * 2}px)`,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Box className={styles.dialog_wrap}>
              <Disabled
                disabled={hasChanges || Object.keys(selectedUser).length === 0}
              >
                <Tabs
                  value={index}
                  onChange={handleChange}
                  aria-label="User Information"
                >
                  <Tab label="User Details" {...a11yProps(0)} />
                  <Tab label="User Connections" {...a11yProps(1)} />
                </Tabs>
              </Disabled>

              <div>
                <TabPanel value={index} index={0} className="tabpanel">
                  <UserScreen
                    user={selectedUser}
                    setHasChanges={setHasChanges}
                    hasChanges={hasChanges}
                    handleClose={handleClose}
                  ></UserScreen>
                </TabPanel>
                <TabPanel value={index} index={1} className="tabpanel">
                  <UserConnections
                    user={selectedUser}
                    setHasChanges={setHasChanges}
                    hasChanges={hasChanges}
                    handleClose={handleClose}
                    groups={groups}
                  ></UserConnections>
                </TabPanel>
              </div>
            </Box>

            <BottomDialogAppBar
              drawerWidth={drawerWidth}
              setHasChanges={setHasChanges}
            ></BottomDialogAppBar>
          </Box>
        </Box>
      </StyledDialog>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps, nextProps)) {
    return true;
  }

  return false; // donot re-render
};

export default React.memo(UsersDialog, areEqual);
