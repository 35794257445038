import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getDevices, getGroups } from "./deviceListAPI";
import { getDeviceCommands, sendDeviceCommand } from "./deviceCommandAPI";
import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

const initialState = {
  filter: "",
  devices: [],
  items: [],
  groups: [],
  commands: [],
  commandResponse: {},
  selectedId: -1,
  selectedIds: [],
  status: "idle",
  event: {},
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const getDevicesAsync = createAsyncThunk(
  "DeviceList/getDevices",
  async (UserId) => {
    const response = await getDevices(UserId);

    // The value we return becomes the `fulfilled` action payload

    return response.data;
  }
);

export const getDeviceCommandsAsync = createAsyncThunk(
  "DeviceList/getDeviceCommands",
  async (deviceId) => {
    const response = await getDeviceCommands(deviceId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const sendDeviceCommandAsync = createAsyncThunk(
  "DeviceList/sendDeviceCommand",
  async (data) => {
    const response = await sendDeviceCommand(data);
    // The value we return becomes the `fulfilled` action payload
    return response;
  }
);

export const getGroupsAsync = createAsyncThunk(
  "DeviceList/getGroups",
  async (UserId) => {
    const response = await getGroups(UserId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const deviceListSlice = createSlice({
  name: "deviceList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updatePositions(state, action) {
      action.payload.forEach((item) => {
        // Refactor malformed lat/lon check into a function
        const validateCoordinate = (value, max) =>
          Math.abs(value) > max ? 0 : value;
        item.latitude = validateCoordinate(item.latitude, 90);
        item.longitude = validateCoordinate(item.longitude, 180);

        // Simplify object update
        const existingItem = state.items[item.deviceId] || {};

        let newItem = { ...existingItem, ...item };

        if (
          item.attributes &&
          item.attributes.battery === undefined &&
          existingItem.attributes &&
          existingItem.attributes.battery
        ) {
          newItem.attributes.battery = existingItem.attributes.battery;
        }

        // Streamline device status and position updates
        if (state.devices[item.deviceId]) {
          const deviceUpdate = {
            lastUpdate: item.serverTime,
            positionId: item.id,
            status: item.attributes?.ignition ? "online" : "offline",
          };

          state.devices[item.deviceId] = {
            ...state.devices[item.deviceId],
            ...deviceUpdate,
          };
        }

        state.items[item.deviceId] = newItem;
      });
    },
    setFilter(state, action) {
      state.filter = action.payload;
    },
    setSelectedId(state, action) {
      state.selectedId = action.payload;
    },
    refresh(state, action) {
      action.payload.forEach((item) => {
        state.devices[item.id] = item;
      });
    },
    updateDevices(state, action) {
      const { id, lastUpdate, status } = action.payload;
      const savedItem = state.devices[id];

      savedItem.lastUpdate = lastUpdate;
      savedItem.status = status;
    },
    updateItems(state, action) {
      action.payload.forEach((item) => {
        state.devices[item.id] = item;
      });
    },
    select(state, action) {
      const index = state.selectedIds.indexOf(action.payload);
      if (index === -1) {
        state.selectedIds.push(action.payload);
      } else {
        state.selectedIds.splice(index, 1);
      }
    },
    selectSingleId(state, action) {
      state.selectedIds = [];
      state.selectedIds.push(action.payload);
    },
    selectAllIds(state, action) {
      const devices = state.devices
        .filter(
          (device) =>
            device?.name.toLowerCase().includes(state.filter.toLowerCase()) |
            state.groups[device.groupId]?.name
              .toLowerCase()
              .includes(state.filter.toLowerCase())
        )
        .map((obj) => obj.id);

      if (devices.length <= 100) {
        state.selectedIds = devices;
      }
      // state.selectedIds = state.devices
      //         .filter(
      //           device =>
      //             device.name
      //               .toLocaleLowerCase()
      //               .includes(currentState.filter.toLocaleLowerCase()) |
      //             device.groupName
      //               .toLocaleLowerCase()
      //               .includes(currentState.filter.toLocaleLowerCase())
      //         )
      //         .map((obj) => obj.id);
    },
    clearAllSelections(state, action) {
      state.selectedIds = [];
    },
    clearAllDevices(state, action) {
      state.devices = [];
    },
    clearAllGroups(state, action) {
      state.groups = [];
    },
    clearAllSelectedDevices(state, action) {
      state.selectedIds = [];
    },
    addListDevice(state, action) {
      state.devices[action.payload.id] = action.payload;
    },
    removeListDevice(state, action) {
      const newDevices = [];

      state.devices.forEach((el, i) => {
        if (el.id !== action.payload) {
          newDevices[el.id] = el;
        }
      });

      //Because only 1 item can be selected for delete selected id and ids should be 0
      state.selectedId = -1;
      state.selectedIds = [];
      state.devices = newDevices;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDevicesAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDevicesAsync.fulfilled, (state, action) => {
        action.payload.forEach((item) => {
          state.devices[item.id] = item;
        });
        state.status = "idle";
        //state.devices = action.payload;
      })

      .addCase(getDeviceCommandsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDeviceCommandsAsync.fulfilled, (state, action) => {
        state.commands = action.payload;
        state.status = "idle";
      })

      .addCase(sendDeviceCommandAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendDeviceCommandAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.commandResponse = action.payload;
      })

      .addCase(getGroupsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getGroupsAsync.fulfilled, (state, action) => {
        action.payload.forEach((item) => {
          state.groups[item.id] = item;
        });
        state.status = "idle";
      });
  },
});

export const {
  updatePositions,
  setFilter,
  setSelectedId,
  select,
  selectSingleId,
  selectAllIds,
  clearAllSelections,
  clearAllDevices,
  clearAllGroups,
  clearAllSelectedDevices,
  refreshDevices,
  updateDevices,
  updateItems,
  addListDevice,
  removeListDevice,
} = deviceListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectPosition = (id) => (state) => {
  return state.deviceList.items[id];
};

export const selectPositions = (ids) => (state) => {
  if (!ids || ids.length < 1) {
    return null;
  }

  const positions = [];

  ids.forEach((id, index) => {
    positions.push(state.deviceList.items[id]);
  });
  return positions;
};

export const selectDevices = (state) => Object(state.deviceList.devices);

export const selectListCount = (state) =>
  new Set(state.deviceList.devices).size - 1;
export const selectDeviceGroups = (state) => Object(state.deviceList.groups);

export const selectSelectedId = (state) => state.deviceList.selectedId;
export const selectSelectedIds = (state) => state.deviceList.selectedIds;
export const selectFilter = (state) => state.deviceList.filter;

export const selectSelectedDeviceGroup = (groupid) => (state) => {
  return state.deviceList.groups[groupid];
};

export const selectDevice = (id) => (state) => {
  return state.deviceList.devices[id];
};

export const selectSelectedDevice = (id) => (state) => {
  return state.deviceList.devices[id];
};
export const selectSelectedDevices = (ids) => (state) => {
  // if (!ids || ids.length < 1) {
  //   return null;
  // }
  // if (state.deviceList.status === "idle") {

  const devices = [];
  ids.forEach((id, index) => {
    devices.push(state.deviceList.devices[id]);
  });

  return devices;
  // }
  // return null;
};

// Object.values(state.devices[]);
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const selectDeviceCommands = (state) =>
  Object.values(state.deviceList.commands);

export const selectDeviceCommandsQueryState = (state) =>
  state.deviceList.status;

export const selectDeviceMenuEvent = (state) => state.deviceList.event;

export default deviceListSlice.reducer;
