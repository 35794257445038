import _ from "lodash";

const getMinOrMax = (markersObj, minOrMax, latOrLng) => {
  if (minOrMax == "max") {
    return _.maxBy(markersObj, function (value) {
      return value[latOrLng];
    })[latOrLng];
  } else {
    return _.minBy(markersObj, function (value) {
      return value[latOrLng];
    })[latOrLng];
  }
};

const createBounds = (markersObj) => {
  var maxLat = getMinOrMax(markersObj, "max", "lat");
  var minLat = getMinOrMax(markersObj, "min", "lat");
  var maxLng = getMinOrMax(markersObj, "max", "lng");
  var minLng = getMinOrMax(markersObj, "min", "lng");

  var southWest = [minLng, minLat];
  var northEast = [maxLng, maxLat];
  return [southWest, northEast];
};

export function getBounds(markers) {
  if (markers?.length > 0) {
    const lngLatBounds = [];
    markers.forEach((position, index, arr) => {
      const coord = {};
      coord.lng = position?.longitude;
      coord.lat = position?.latitude;

      if (coord.lng && coord.lat) {
        lngLatBounds.push(coord);
      }
    });

    if (lngLatBounds.length > 0) {
      return createBounds(lngLatBounds);
    }
  }

  return null;
}

export function getBoundsForPoint(point) {
  if (Object.keys(point).length !== 0) {
    const lngLatBounds = [];

    const coord = {};
    coord.lng = point?.longitude;
    coord.lat = point?.latitude;

    lngLatBounds.push(coord);

    return createBounds(lngLatBounds);
  }
  return null;
}
