import http from "../../services/httpService";
import config from "../../config.json";

export async function getGroupsLinked(user) {
  const response = await http.get(
    config.apiUrl + `/groups?userId=${user.id}`,
    user,
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}

export async function getGroupsAll(user) {
  const response = await http.get(config.apiUrl + `/groups?all=true`, user, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      "Content-Type": "application/json",
    },
  });

  return response;
}
