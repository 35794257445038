import StopsTable from "./StopsTable";
const StopsHistory = (props) => {
  return (
    <div className="chartContainer">
      {props.SummarizedStops?.stops?.length > 0 ? (
        <StopsTable stopsData={props.SummarizedStops} id={props.id} />
      ) : (
        <p>No Stop Data, Get Data.</p>
      )}
    </div>
  );
};
export default StopsHistory;
