import http from "../../services/httpService";
import config from "../../config.json";

export async function addPermissions(added) {
  console.log(added);
  const response = await http.post(config.apiUrl + "/permissions", added, {
    headers: {
      // Overwrite Axios's automatically set Content-Type
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
}

export async function deletePermissions(removed) {
  console.log(removed);
  const response = await http.delete(config.apiUrl + "/permissions", {
    data: removed,
    headers: {
      // Overwrite Axios's automatically set Content-Type
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  return response;
}
