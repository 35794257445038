import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { getUsers } from "./userListApi";

const initialState = {
  userFilter: "",
  users: [],
  selectedUser: {},
  status: "idle",
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getUsersAsync = createAsyncThunk("userList/getUsers", async () => {
  const response = await getUsers();
  // The value we return becomes the `fulfilled` action payload
  return response.data;
});

export const userListSlice = createSlice({
  name: "userList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setUserFilter(state, action) {
      state.userFilter = action.payload;
    },
    setSelectedUser(state, action) {
      state.selectedUser = action.payload;
    },
    addListUser(state, action) {
      state.users[action.payload.id] = action.payload;
    },
    updateListUser(state, action) {
      state.users[action.payload.id] = action.payload;
    },
    removeListUser(state, action) {
      const newUsers = [];

      state.users.forEach((el, i) => {
        if (el.id !== action.payload) {
          newUsers[el.id] = el;
        }
      });

      //Because only 1 item can be selected for delete selected id and ids should be 0
      state.selectedUser = {};
      state.users = newUsers;
    },
  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getUsersAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getUsersAsync.fulfilled, (state, action) => {
        action.payload.forEach((item) => {
          state.users[item.id] = item;
        });

        state.status = "idle";
      });
  },
});

export const {
  setUserFilter,
  setSelectedUser,
  addListUser,
  updateListUser,
  removeListUser,
} = userListSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectListCount = (state) =>
  new Set(state.userList.users).size - 1;
export const selectUsers = (state) => Object(state.userList.users);
export const selectUserFilter = (state) => state.userList.userFilter;
export const selectSelectedUser = (state) =>
  Object(state.userList.selectedUser);

// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export default userListSlice.reducer;
