import React, { useState, useEffect } from "react";
import { geoCodeAsync } from "./geocodeSlice";
import { Link } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

const ShowAddress = (props, actions) => {
  const [address, setAddress] = useState();
  const [currentDeviceId, setCurrentDeviceId] = useState();

  const { latitude, longitude, deviceId } = props;

  const dispatch = useDispatch();

  useEffect(() => {
    if (deviceId !== currentDeviceId) {
      setCurrentDeviceId(deviceId);
      setAddress(null);
    }
  }, [deviceId]);

  const handleShowAddress = async (actions) => {
    try {
      const coords = {};
      coords.latitude = latitude;
      coords.longitude = longitude;

      const response = await dispatch(geoCodeAsync(coords)).unwrap();

      if (response.status === 200) {
        setAddress(response.data);
      }
    } catch (err) {
      setAddress(null);
    }
  };

  if (address) {
    return address;
  }

  return (
    <Link href="#" onClick={handleShowAddress}>
      Show Address
    </Link>
  );
};

export default ShowAddress;
