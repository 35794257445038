import { useRef, useEffect } from "react";
import { async } from "./../../login/loginAPI";
import moment from "moment";

export async function useSumerizeStops(stops) {
  const summarizedData = {};

  summarizedData.stops = [];

  let lastTs = moment("2007-03-01T13:00:00Z");

  stops.forEach((stop, index, array) => {
    summarizedData.stops.push(stop);
  });

  return summarizedData;
}
