import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import FormLabel from "@mui/material/FormLabel";
import MenuItem from "@mui/material/MenuItem";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import TextField from "@mui/material/TextField";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import IconButton from "@mui/material/IconButton";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { toast } from "react-toastify";
import _ from "lodash";

import { deviceFormToJson } from "../../app/converters/deviceConverters";
import { addDeviceAsync, updateDeviceAsync } from "../system/deviceSlice";
import { addListDevice } from "../deviceList/deviceListSlice";

import { useFormik } from "formik";
import * as yup from "yup";
import "yup-phone";

import { useTheme } from "@mui/material/styles";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  root: {
    "&+.MuiDialogContent-root": {
      paddingTop: "10px !important",
    },
  },
  "& MuiDialog-paper": {
    padding: theme.spacing(2),
    position: "absolute",
    top: theme.spacing(5),
  },
  "& .MuiTypography-h6": {
    paddingRight: "0px",
  },
}));

const DeviceDialog = (props) => {
  const { open, handleClose, device, groups } = props;

  const [lastUpdate, setLastUpdate] = React.useState(null);

  const [positionId, setPositionId] = React.useState(0);

  const dispatch = useDispatch();

  const [attributes, setAttributes] = React.useState([]);

  const [overrideImeiBoxDisabled, setOverrideImeiBoxDisabled] =
    React.useState(false);
  const [imeiBoxDisabled, setImeiBoxDisabled] = React.useState(true);

  const [cardOpen, setCardOpen] = React.useState(false);
  const [attribOpen, setAttribOpen] = React.useState(false);

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  const handleEnableIMEIBox = (event) => {
    setOverrideImeiBoxDisabled(!overrideImeiBoxDisabled);
    setImeiBoxDisabled(!imeiBoxDisabled);
  };

  useEffect(() => {
    if (device) {
      const localAttributes = [];

      setLastUpdate(device ? device.lastUpdate : null);
      setPositionId(device ? device.positionId : 0);

      Object.entries(device.attributes).forEach(([key, value]) => {
        const attribute = {};

        attribute.name = key;
        attribute.value = value;
        localAttributes.push(attribute);
      });
      if (!overrideImeiBoxDisabled) {
        setImeiBoxDisabled(true);
      }
      setAttributes([...localAttributes]);
    } else {
      setAttributes([]);
      setImeiBoxDisabled(false);
    }

    setCardOpen(false);
    setAttribOpen(false);
  }, [device]);

  const handleSubmit = async (formValues, actions) => {
    try {
      const deviceToEdit = deviceFormToJson(formValues, attributes, device);

      let response = {};

      if (device) {
        response = await dispatch(updateDeviceAsync(deviceToEdit)).unwrap();
      } else {
        response = await dispatch(addDeviceAsync(deviceToEdit)).unwrap();
      }

      const newDevice = _.cloneDeep(response.data);

      if (lastUpdate) {
        newDevice.lastUpdate = lastUpdate;
      }

      if (positionId) {
        newDevice.positionId = positionId;
      }

      if (response.status === 200) {
        toast.success("Operation Successfull.");
        dispatch(addListDevice(newDevice));
        handleClose();
      }
    } catch (err) {
      if (err.message === "Identifier already in use!") {
        actions.setErrors({
          identifier: err.message,
        });
      } else {
        toast.error(err.message);
      }
    }
  };

  const handleLocalClose = async () => {
    handleClose();
  };

  const addAttribute = () => {
    const attribute = {};

    attribute.name = formik.values.attribName;
    attribute.value = formik.values.attribValue;

    formik.values.attribName = "";
    formik.values.attribValue = "";

    setAttributes([...attributes, attribute]);
  };

  const removeAttribute = (attribute) => {
    setAttributes(attributes.filter((item) => item !== attribute));
  };

  const attributesOk = () => {
    if (
      formik.values.attribName === undefined ||
      formik.values.attribName === "" ||
      formik.values.attribValue === undefined ||
      formik.values.attribValue === ""
    ) {
      return false;
    }
    return true;
  };

  const phoneRegExp =
    /^(?:(?:\(?(?:0(?:0|11)\)?[\s-]?\(?|\+)44\)?[\s-]?(?:\(?0\)?[\s-]?)?)|(?:\(?0))(?:(?:\d{5}\)?[\s-]?\d{4,5})|(?:\d{4}\)?[\s-]?(?:\d{5}|\d{3}[\s-]?\d{3}))|(?:\d{3}\)?[\s-]?\d{3}[\s-]?\d{3,4})|(?:\d{2}\)?[\s-]?\d{4}[\s-]?\d{4}))(?:[\s-]?(?:x|ext\.?|\#)\d{3,4})?$/; // eslint-disable-line

  const validationSchema = yup.object({
    name: yup.string("Enter a name").required("Name is required"),
    identifier: yup
      .string("Enter an identifier")
      .required("Identifier is required"),
    model: yup
      .string()
      .max(60, "Model should be no more than 60 characters long.")
      .nullable(),
    contact: yup
      .string()
      .max(60, "Contact should be no more than 60 characters long.")
      .nullable(),
    groupId: yup
      .number()
      .typeError("Group is invalid")
      .nullable()
      .moreThan(0, "Floor area cannot be negative")
      .transform((_, val) => (val !== "" ? Number(val) : null)),
    phone: yup
      .string()
      .matches(phoneRegExp, "Phone number is not valid")
      .nullable(),
    attribName: yup.string(),
    attribValue: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      name: device ? device.name : "",
      identifier: device ? device.uniqueId : "",
      groupId: device ? device.groupId : "",
      phone: device ? device?.phone : "",
      model: device ? device?.model : "",
      contact: device ? device?.contact : "",
      attribName: "",
      attribValue: "",
    },
    enableReinitialize: true,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: false,
    initialTouched: device
      ? {}
      : {
          name: true,
          identifier: true,
          attribName: true,
          attribValue: true,
        },
    initialErrors: device ? {} : { name: "Required", identifier: "Required" },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  return (
    <div>
      <StyledDialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="Add Device"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            padding: 1,
            backgroundColor: theme.palette.primary.dark,
            color: theme.palette.secondary.contrastText,
            fontSize: "1em",
          }}
        >
          {"Add Device"}
        </DialogTitle>
        <DialogContent sx={{ padding: 1 }}>
          <form
            variant="standard"
            sx={{ m: 1, minWidth: 120 }}
            onSubmit={formik.handleSubmit}
          >
            <fieldset className="fieldSetInfoGreen">
              <DialogContentText>
                Enter any name you can use to easily identify your plant or
                vehicle and the identifier your tracker will report to the
                portal, usually IMEI or Serial.
              </DialogContentText>
            </fieldset>
            <fieldset className="">
              <legend>Required</legend>
              <TextField
                margin="dense"
                id="name"
                label="Name"
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                disabled={imeiBoxDisabled}
                onDoubleClick={handleEnableIMEIBox}
                margin="dense"
                id="identifier"
                label="Identifier"
                type="text"
                fullWidth
                variant="standard"
                value={formik.values.identifier}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.identifier && Boolean(formik.errors.identifier)
                }
                helperText={
                  formik.touched.identifier && formik.errors.identifier
                }
              />
            </fieldset>
            <fieldset className="">
              <legend>
                Extra Information
                <IconButton
                  className="legendExpander"
                  sx={{
                    width: "20px",
                    height: "10px",
                  }}
                  onClick={() => setCardOpen(!cardOpen)}
                >
                  {cardOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </legend>

              <Collapse
                in={cardOpen}
                timeout="auto"
                orientation="vertical"
                unmountOnExit
              >
                <div>
                  <TextField
                    margin="dense"
                    id="model"
                    name="model"
                    label="Model"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formik.values.model}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.model && Boolean(formik.errors.model)}
                    helperText={formik.touched.model && formik.errors.model}
                  />
                  <TextField
                    id="groupId"
                    name="groupId"
                    select
                    margin="dense"
                    fullWidth
                    variant="standard"
                    value={formik.values.groupId}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.groupId && Boolean(formik.errors.groupId)
                    }
                    helperText={formik.touched.groupId && formik.errors.groupId}
                    label="Group"
                  >
                    <MenuItem
                      key={0}
                      value=""
                      style={{ height: 30 }}
                    ></MenuItem>
                    {groups.map((group) => (
                      <MenuItem key={group.id} value={group.id}>
                        {group.name}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    margin="dense"
                    id="contact"
                    name="contact"
                    label="Contact"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formik.values.contact}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.contact && Boolean(formik.errors.contact)
                    }
                    helperText={formik.touched.contact && formik.errors.contact}
                  />
                  <TextField
                    margin="dense"
                    id="phone"
                    name="phone"
                    label="Phone"
                    type="text"
                    fullWidth
                    variant="standard"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />
                </div>
              </Collapse>
            </fieldset>
            <fieldset className="fieldSetRequiredAddDevice">
              <legend>
                Attributes
                <IconButton
                  className="legendExpander"
                  sx={{
                    width: "20px",
                    height: "10px",
                  }}
                  onClick={() => setAttribOpen(!attribOpen)}
                >
                  {attribOpen ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </IconButton>
              </legend>

              <Collapse
                in={attribOpen}
                timeout="auto"
                orientation="vertical"
                unmountOnExit
                sx={{ padding: "0.05px" }}
              >
                <List
                  sx={{
                    width: "100%",

                    bgcolor: "background.paper",
                  }}
                >
                  {attributes.map((attribute, index) => (
                    <ListItem
                      key={index}
                      sx={{ paddingLeft: 0, paddingRight: 0 }}
                      secondaryAction={
                        <IconButton
                          aria-label="comment"
                          onClick={() => removeAttribute(attribute)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                      }
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={5}>
                          <FormLabel>{`${attribute.name}`}</FormLabel>
                        </Grid>
                        <Grid item xs={6}>
                          <FormLabel>{`${attribute.value}`}</FormLabel>
                        </Grid>
                        <Grid item xs={1}></Grid>
                      </Grid>
                    </ListItem>
                  ))}
                </List>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={5}>
                      <TextField
                        id="attribName"
                        name="attribName"
                        margin="dense"
                        fullWidth
                        variant="standard"
                        label="Name"
                        value={formik.values.attribName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.attribName &&
                          Boolean(formik.errors.attribName)
                        }
                      ></TextField>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="attribValue"
                        name="attribValue"
                        margin="dense"
                        fullWidth
                        variant="standard"
                        label="Value"
                        value={formik.values.attribValue}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          formik.touched.attribValue &&
                          Boolean(formik.errors.attribValue)
                        }
                        helperText={
                          formik.touched.attribValue &&
                          formik.errors.attribValue
                        }
                      ></TextField>
                    </Grid>

                    <Grid item xs={8}>
                      <Button
                        disabled={attributesOk() ? false : true}
                        onClick={addAttribute}
                      >
                        Add
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Collapse>
            </fieldset>
            <DialogActions>
              <Button autoFocus disabled={!formik.isValid} type="submit">
                Save
              </Button>
              <Button autoFocus onClick={handleLocalClose}>
                Cancel
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </StyledDialog>
    </div>
  );
};

const areEqual = (prevProps, nextProps) => {
  if (_.isEqual(prevProps, nextProps)) {
    return true;
  }

  return false; // donot re-render
};

export default React.memo(DeviceDialog, areEqual);
