import React, { useEffect, Fragment } from "react";
import Box from "@mui/material/Box";

import Container from "@mui/material/Container";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import AllDataTable from "./AllDataTable";

const AllData = (props) => {
  // const [data, setData] = React.useState(props.SummarizedPositions.allData);
  // const memoizedData = React.useMemo(() => data, [data]);

  return (
    <div className="chartContainer">
      {props.SummarizedPositions.allData.length > 1 ? (
        <Box className="tripContainer">
          <AllDataTable
            rows={props.SummarizedPositions.allData}
            className="tableBody"
          />
        </Box>
      ) : (
        <p>No Trip Data, Get Data.</p>
      )}
    </div>
  );
};
export default AllData;
