import React from "react";
import { styled, alpha, useTheme } from "@mui/material/styles";
import SvgIcon from "@mui/material/SvgIcon";
import { ReactComponent as Logo } from "../../assets/tractor.svg";
import { Badge, Avatar } from "@mui/material";

const DeviceLogo = (props) => {
  const { status } = props;

  const theme = useTheme();
  const StyledBadgeActive = styled(Badge)(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& .MuiBadge-badge": {
      backgroundColor: `${theme.palette.success.light}`,
      color: `${theme.palette.success.light}`,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        animation: "ripple 1.2s infinite ease-in-out",
        border: "1px solid currentColor",
        content: '""',
      },
    },
    "@keyframes ripple": {
      "0%": {
        transform: "scale(.8)",
        opacity: 1,
      },
      "100%": {
        transform: "scale(2.4)",
        opacity: 0,
      },
    },
  }));
  const StyledBadgeInactive = styled(Badge)(({ theme }) => ({
    width: "100%",
    height: "100%",
    "& .MuiBadge-badge": {
      backgroundColor: `${theme.palette.error.light}`,
      color: `${theme.palette.error.light}`,
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        border: "1px solid currentColor",
        content: '""',
      },
    },
  }));

  return (
    <>
      {status === "online" ? (
        <StyledBadgeActive
          id="deviceAvatarActive"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          variant="dot"
        >
          <Avatar
            sx={{
              width: 1,
              height: 1,
              backgroundColor: alpha(theme.palette.primary.dark, 1),
            }}
          >
            {/* <Battery2BarIcon /> */}
            <SvgIcon
              sx={{
                width: 0.8,
                height: 0.8,
              }}
            >
              <Logo />
            </SvgIcon>
          </Avatar>
        </StyledBadgeActive>
      ) : (
        <StyledBadgeInactive
          id="deviceAvatarActive"
          overlap="circular"
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          variant="dot"
        >
          <Avatar
            sx={{
              width: 1,
              height: 1,
              backgroundColor: alpha(theme.palette.primary.dark, 1),
            }}
          >
            {/* <Battery2BarIcon /> */}
            <SvgIcon
              sx={{
                width: 0.8,
                height: 0.8,
              }}
            >
              <Logo />
            </SvgIcon>
          </Avatar>
        </StyledBadgeInactive>
      )}
    </>
  );
};

export default DeviceLogo;
