import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//import maplibregl from "maplibre-gl";
// import maplibreglWorker from "maplibre-gl/dist/maplibre-gl-csp-worker";
import Map, { NavigationControl } from "react-map-gl";
import styles from "./Map.module.css";
import MapAdornments from "./MapAdornments";
import MapTrace from "./MapTrace";
import MapPoint from "./MapPoint";

import { selectCurrentUser } from "../login/loginSlice";

import {
  selectDeviceTrace,
  selectDevicePoint,
} from "../deviceList/devicePositionsSlice";

import {
  setDetailsDrawOpen,
  selectDetailsDrawOpen,
} from "../map/mapStateSlice";

import { setSelectedId } from "../deviceList/deviceListSlice";

import MapLayerSelector from "./MapLayerSelector";
import { setManualMove, selectManualMove } from "../map/mapStateSlice";

//maplibregl.workerClass = maplibreglWorker;

const MapHolder = (props) => {
  const { selectedIds, position, devices, positions } = props;

  const currentUser = useSelector(selectCurrentUser);

  const devicePointer = require("./navigator32.png");
  const deviceMarker = require("./marker.png");
  const startImage = require("./start.png");
  const finishImage = require("./finish.png");
  const stopImage = require("./stop.png");

  const [pointerContextMenu, setPointerContextMenu] = React.useState(null);

  const [hoverInfo, setHoverInfo] = useState(null);
  const [stopHoverInfo, setStopHoverInfo] = useState(null);
  const [interactiveLayerIds, setInteractiveLayerIds] = useState([]);

  const [projection] = useState("globe");

  const detailsDrawOpen = useSelector(selectDetailsDrawOpen);
  const deviceTrace = useSelector(selectDeviceTrace);
  const devicePoint = useSelector(selectDevicePoint);

  const manualMove = useSelector(selectManualMove);

  const mapRef = React.useRef();
  const dispatch = useDispatch();

  //"mapbox://styles/pncookson/clgv06rdp007401pn5j3sbsot"
  // const mapStyle = useSelector(selectMapStyle);
  const [mapStyle, setMapStyle] = useState(
    "mapbox://styles/mapbox/satellite-streets-v12"
  );
  const onStyleChange = (style) => {
    const map = mapRef.current.getMap();
    //This cures the flickering at high zoom FFS
    map.setProjection("globe");
    setMapStyle(style);
  };

  const onHover = React.useCallback((event) => {
    const {
      features,
      point: { x, y },
    } = event;

    const hoveredFeature = features && features[0];

    // prettier-ignore

    if (hoveredFeature?.layer?.id === "lineMarkers") {
 
      setHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    } else{
      setHoverInfo(null);
    }

    if (hoveredFeature?.layer?.id === "pointLayer") {
      setStopHoverInfo(hoveredFeature && { feature: hoveredFeature, x, y });
    } else {
      setStopHoverInfo(null);
    }
  }, []);

  const onClick = React.useCallback(
    (event) => {
      const { features } = event;

      const hoveredFeature = features && features[0];

      if (hoveredFeature?.layer?.id === "markerLayer") {
        dispatch(setSelectedId(hoveredFeature.properties.id));

        setPointerContextMenu(
          pointerContextMenu === null
            ? {
                mouseX: event.originalEvent.clientX + 2,
                mouseY: event.originalEvent.clientY - 6,
              }
            : null
        );
      } else {
        setPointerContextMenu(null);
      }
    },
    [dispatch, pointerContextMenu]
  );

  const handleDesktopDrawerToggle = () => {
    dispatch(setDetailsDrawOpen(!detailsDrawOpen));
  };

  const handlePointerContextMenuClose = (command, position) => (event) => {
    setPointerContextMenu(null);

    if (command === "showSideScreen") {
      handleDesktopDrawerToggle();
    }
    if (command === "googleMaps") {
      if (position !== undefined && position !== null) {
        const newWindow = window.open(
          "https://maps.google.com?q=" +
            position.latitude +
            "," +
            position.longitude,
          "_blank",
          "noopener,noreferrer"
        );
        if (newWindow) newWindow.opener = null;
      }
    }
  };

  useEffect(() => {
    dispatch(setManualMove(false));
  }, [selectedIds, dispatch]);

  const onMapLoad = React.useCallback(() => {
    if (!mapRef) return;
    if (!mapRef?.current) return;
    const map = mapRef.current.getMap();

    setInteractiveLayerIds(["lineMarkers", "markerLayer", "pointLayer"]);

    map.loadImage(devicePointer, (error, image) => {
      if (error) throw error;
      if (!map.hasImage("devicepointer")) map.addImage("devicepointer", image);
    });

    map.loadImage(deviceMarker, (error, image) => {
      if (error) throw error;
      if (!map.hasImage("devicemarker")) map.addImage("devicemarker", image);
    });

    map.loadImage(startImage, (error, image) => {
      if (error) throw error;
      if (!map.hasImage("start")) map.addImage("start", image);
    });

    map.loadImage(finishImage, (error, image) => {
      if (error) throw error;
      if (!map.hasImage("finish")) map.addImage("finish", image);
    });

    map.loadImage(stopImage, (error, image) => {
      if (error) throw error;
      if (!map.hasImage("stop")) map.addImage("stop", image);
    });

    mapRef.current.on("drag", () => {
      dispatch(setManualMove(true));
    });

    mapRef.current.on("rotate", () => {
      dispatch(setManualMove(true));
    });

    map.on("zoom", function (e) {
      if (e.originalEvent) {
        //could test for original event tyle
        //but it seems any user generated zoom event
        //will have an originalEvent property so thats better

        //const zoom = e.viewState.zoom;

        // const map = mapRef.current.getMap();
        // const projection = map.getProjection();

        // if (zoom > 8) {
        //   if (projection.name !== "mercator") {

        //     map.setProjection("mercator");
        //   }
        // } else {
        //   if (projection.name !== "globe") {

        //     map.setProjection("globe");
        //   }
        // }

        dispatch(setManualMove(true));
      }
    });

    map.on("style.load", function (e) {
      map.loadImage(devicePointer, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("devicepointer"))
          map.addImage("devicepointer", image);
      });

      map.loadImage(deviceMarker, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("devicemarker")) map.addImage("devicemarker", image);
      });

      map.loadImage(startImage, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("start")) map.addImage("start", image);
      });

      map.loadImage(finishImage, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("finish")) map.addImage("finish", image);
      });

      map.loadImage(stopImage, (error, image) => {
        if (error) throw error;
        if (!map.hasImage("stop")) map.addImage("stop", image);
      });
    });
  });

  let initViewState = {
    longitude: -2.1147466,
    latitude: 52.6261816,
    zoom: 3,
  };

  if (currentUser) {
    if (currentUser?.data.latitude && currentUser?.data.longitude) {
      initViewState.longitude = currentUser.data.longitude;
      initViewState.latitude = currentUser.data.latitude;
    }
    if (currentUser?.data.zoom) {
      initViewState.zoom = currentUser.data.zoom;
    }
  } else {
    console.log("NO");
  }

  return (
    <div className={styles.map_wrap}>
      <MapLayerSelector StyleChange={onStyleChange}></MapLayerSelector>

      <Map
        ref={mapRef}
        onLoad={onMapLoad}
        className={styles.map}
        projection={projection}
        reuseMaps
        id="mainMap"
        //mapLib={maplibregl}
        mapStyle={mapStyle}
        onMouseMove={onHover}
        onClick={onClick}
        initialViewState={initViewState}
        interactiveLayerIds={interactiveLayerIds}
        mapboxAccessToken={
          "pk.eyJ1IjoicG5jb29rc29uIiwiYSI6ImNsZXBwN2g3aDA2ZnQzeHFnMjkycGN5dmcifQ.WZln0ke3zhlpCmFufk-IXg"
        }
      >
        {/* <Pins id={selectedId}></Pins> */}
        {hoverInfo && (
          <div
            className={styles.tooltip}
            style={{ left: hoverInfo.x, top: hoverInfo.y }}
          >
            <div>Time: {hoverInfo.feature.properties.time}</div>
            <div>Heading: {hoverInfo.feature.properties.heading}</div>
            <div>Speed: {hoverInfo.feature.properties.speed}</div>
          </div>
        )}

        {stopHoverInfo && (
          <div
            className={styles.tooltip}
            style={{ left: stopHoverInfo.x, top: stopHoverInfo.y }}
          >
            <div>
              Start Time:
              {stopHoverInfo.feature.properties.startTime}
            </div>

            <div>
              End Time:
              {stopHoverInfo.feature.properties.endTime}
            </div>
            <div>
              Duration:
              {stopHoverInfo.feature.properties.duration}
            </div>
            <div>
              Address:
              {stopHoverInfo.feature.properties.address}
            </div>
          </div>
        )}

        <MapAdornments
          devices={devices}
          positions={positions}
          hasMapMoved={manualMove}
        />
        <MapTrace deviceTrace={deviceTrace} />

        <MapPoint devicePoint={devicePoint} />

        <NavigationControl />
      </Map>

      <Menu
        open={pointerContextMenu !== null}
        anchorReference="anchorPosition"
        anchorPosition={
          pointerContextMenu !== null
            ? {
                top: pointerContextMenu.mouseY,
                left: pointerContextMenu.mouseX,
              }
            : undefined
        }
      >
        <MenuItem
          onClick={handlePointerContextMenuClose("showSideScreen", position)}
        >
          Device Details
        </MenuItem>
        <MenuItem
          onClick={handlePointerContextMenuClose("googleMaps", position)}
        >
          View On Google Maps
        </MenuItem>
        <MenuItem
          onClick={handlePointerContextMenuClose("closeMenu", position)}
        >
          Cancel
        </MenuItem>
      </Menu>
    </div>
  );
};

export default MapHolder;
