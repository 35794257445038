import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getPositions } from "./positionsApi";
import moment from "moment";

const initialState = {
  status: "idle",
  devicePositions: {},
  traceView: { id: -1, startDate: null, endDate: null },
  pointView: {
    id: -1,
    startTime: null,
    endTime: null,
    address: null,
    duration: null,
    latitude: null,
    longitude: null,
    type: null,
  },
};

export const getPositionsAsync = createAsyncThunk(
  "DevicePositionList/getPositions",
  async (params) => {
    const deviceId = params[0];
    const from = params[1];
    const to = params[2];
    const response = await getPositions(deviceId, from, to);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const devicePositionsSlice = createSlice({
  name: "devicePositionsList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    clearPositions(state, action) {
      state.devicePositions = {};
    },
    showTraceOnMap(state, action) {
      state.traceView = action.payload;
    },
    clearTraceOnMap(state, action) {
      state.traceView = { id: -1, startDate: null, endDate: null };
    },
    showPointOnMap(state, action) {
      state.pointView = action.payload;
    },
    clearPointOnMap(state, action) {
      state.pointView = {
        id: -1,
        startTime: null,
        endTime: null,
        address: null,
        duration: null,
        latitude: null,
        longitude: null,
        type: null,
      };
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getPositionsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPositionsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.devicePositions = action.payload;
      });
  },
});

export const {
  clearPositions,
  showTraceOnMap,
  showPointOnMap,
  clearPointOnMap,
  clearTraceOnMap,
} = devicePositionsSlice.actions;

export const selectDevicePositions = (state) =>
  Object.values(state.devicePositionsList.devicePositions);

export const selectDeviceTrace = (state) => {
  let startTime = state.devicePositionsList.traceView.startTime;
  let endTime = state.devicePositionsList.traceView.endTime;

  if (state.devicePositionsList.traceView.id === -1) {
    return [];
  } else {
    if (state.devicePositionsList.devicePositions.length > 0) {
      const trace = state.devicePositionsList.devicePositions.filter(
        (item) =>
          new Date(item.fixTime) >= startTime &&
          new Date(item.fixTime) <= endTime
      );

      return Object.values(trace);
    }

    return [];
  }
};

export const selectDevicePoint = (state) => {
  let pointInfo = state.devicePositionsList.pointView;
  return pointInfo;
};

export const selectDevicePositionsQueryState = (state) =>
  state.devicePositionsList.status;

export default devicePositionsSlice.reducer;
