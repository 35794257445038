import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getDevices, getGroups } from "./deviceListAPI";
import { getDeviceCommands } from "./deviceCommandAPI";

const initialState = {
  commands: [],
  selectedId: -1,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const getDeviceCommandsAsync = createAsyncThunk(
  "DeviceList/getDeviceCommands",
  async (deviceId) => {
    const response = await getDeviceCommands(deviceId);
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  }
);

export const commandsListSlice = createSlice({
  name: "commandsList",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {},
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(getDeviceCommandsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getDeviceCommandsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.commands = action.payload;
      });
  },
});

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

// Object.values(state.devices[]);
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.

export const selectDeviceCommands = (state) =>
  Object.values(state.commandsList.commands);

export const selectDeviceCommandsQueryState = (state) => state.status;

export default commandsListSlice.reducer;
