import http from "../../services/httpService";
import config from "../../config.json";

export async function getNotificationsLinked(user) {
  const response = await http.get(
    config.apiUrl + `/notifications?userId=${user.id}`,
    user,
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    }
  );

  return response;
}

export async function getNotificationsAll(user) {
  const response = await http.get(
    config.apiUrl + `/notifications?all=true`,
    user,
    {
      headers: {
        // Overwrite Axios's automatically set Content-Type
        "Content-Type": "application/json",
      },
    }
  );
  console.log(response);
  return response;
}
