import { createSlice } from "@reduxjs/toolkit";
// import moment from "moment";

// import { fromJS } from "immutable";
// import OSMBRIGHT from "./mapstyles/osmbright.json";
// import MAPTILER from "./mapstyles/maptiler.json";

//["https://a.tile.openstreetmap.org/{z}/{x}/{y}.png"],
//tiles: ["https://tile.sandrastrackers.com/hot/{z}/{x}/{y}.png"],
//["https://mt0.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"],
//["http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"],
// ["http://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga"],
//ROADS ["http://mt1.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}&s=Ga"],
//["http://mt1.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}&s=Ga&key=AIzaSyDbqILKZDk0KYua3bOshiYY6jFZL95yvBw"],
// h = roads only
// m = standard roadmap
// p = terrain
// r = somehow altered roadmap
// s = satellite only
// t = terrain only
// y = hybrid

//["http://mt1.google.com/vt/lyrs=y&x=1325&y=3143&z=13"],
//["https://a.tiles.mapbox.com/v4/mapbox.mapbox-streets-v8/{z}/{x}/{y}.vector.pbf?access_token={pk.eyJ1IjoicG5jb29rc29uIiwiYSI6ImNsZXBwN2g3aDA2ZnQzeHFnMjkycGN5dmcifQ.WZln0ke3zhlpCmFufk-IXg}"]

//["https://tile.thunderforest.com/transport/{z}/{x}/{y}.png?apikey=21b78adf28d44349999338479295575e"],

//glyphs: "https://tile.sandrastrackers.com/{fontstack}/{range}.pbf",

// const defaultMapStyle = fromJS(OSMBRIGHT);
// const secondaryMapStyle = fromJS(MAPTILER);

//RoadMap: defaultMapStyle,

const mapStyles = {
  RoadMap: "mapbox://styles/mapbox/outdoors-v12",
  Satellite: "mapbox://styles/mapbox/satellite-streets-v12",
};

//pk.eyJ1IjoicG5jb29rc29uIiwiYSI6ImNsZXBwN2g3aDA2ZnQzeHFnMjkycGN5dmcifQ.WZln0ke3zhlpCmFufk-IXg

const initialState = {
  mapStyle: mapStyles["RoadMap"],
  manualMove: false,
  detailsDrawOpen: false,
  zoom: 2,
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.

export const mapStateSlice = createSlice({
  name: "mapState",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    setDetailsDrawOpen(state, action) {
      state.detailsDrawOpen = action.payload;
    },
    setManualMove(state, action) {
      state.manualMove = action.payload;
    },
    setMapStyle(state, action) {
      state.mapStyle = action.payload;
    },
    setMapType(state, action) {
      state.mapStyle = mapStyles[action.payload];
    },
  },
});

export const {
  setViewState,
  setMapStyle,
  setMapType,
  setDetailsDrawOpen,
  setManualMove,
} = mapStateSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectMapStyle = (state) => state.mapState.mapStyle;
export const selectViewState = (state) => state.mapState.viewState;
export const selectViewType = (state) => state.mapState.mapType;
export const selectDetailsDrawOpen = (state) => state.mapState.detailsDrawOpen;
export const selectManualMove = (state) => state.mapState.manualMove;

export default mapStateSlice.reducer;
