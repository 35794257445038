import http from "../../services/httpService";
import config from "../../config.json";

export async function getDevices(userId) {
  try {
    const response = await http.get(config.apiUrl + `/devices`);
    return response;
  } catch (error) {
    return error.response;
  }
}

export async function getGroups(userId) {
  const response = await http.get(config.apiUrl + `/groups`);
  return response;
}
